import React, { createContext, useState } from "react"

export const RendicionContext = createContext({});

const RendicionContextProvider = ({ children }) => {
    const [tipoRendicion, setTipoRendicion] = useState([])
  
    return (
        <RendicionContext.Provider value={{tipoRendicion, setTipoRendicion}}>
            {children}
        </RendicionContext.Provider>
    )
}
export default RendicionContextProvider