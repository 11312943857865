import React, { useContext } from 'react'
import { Text } from 'react-native-paper'
import { View, Platform } from 'react-native'
import styles from './styles'
import CreditCardSVG from '../../resources/menu Lateral/CreditCard.svg'
import CreditCardSVGWhite from '../../resources/menu Lateral/CreditCardWhite.svg'

import SvgFormatter from '../../../svgFormatter';
import { CardContext } from '../../context/CardContext'
import { RendicionContext } from '../../context/RendicionContext'

const HeaderCard = ({style, whiteColor}) => {
    const {card} = React.useContext(CardContext);
    const nombre = card.nombre;
    const numeroTarjeta = card.numeroTarjeta;
    var numeroTarjetaAMostrar = "";
    const { tipoRendicion } = useContext(RendicionContext);
    
    if(numeroTarjeta != null){        
        const lengthNumeroTarjeta = numeroTarjeta.toString().length;
        const cuatroUltimosNumeros = numeroTarjeta.toString().substring(lengthNumeroTarjeta-4,lengthNumeroTarjeta);
        numeroTarjetaAMostrar = "XXXX-XXXX-XXXX-" + cuatroUltimosNumeros;
    }
    
    const CardInfo = () => {        
        if(numeroTarjeta != null && tipoRendicion != 2){
            return(
                <View>
                    <View>
                        <Text style={whiteColor == true ? styles.textNombreWhite : styles.textNombreGrey}>{nombre}</Text>
                    </View>
                    <View style={whiteColor == true ? styles.horizontalLineWhite : styles.horizontalLineGrey} />
                    <View style={styles.subcontainer}>
                    <SvgFormatter SvgPath={whiteColor == true ? CreditCardSVGWhite : CreditCardSVG} styles={styles.CreditCard} />
                    <Text style={whiteColor == true ? styles.tituloWhite : styles.tituloGrey}>{numeroTarjetaAMostrar}</Text>
                    </View>
                </View>
            );
        }

        return(
            <View>
                <View>
                    <Text style={whiteColor == true ? styles.textNombreWhite : styles.textNombreGrey}>{nombre}</Text>
                </View>                
            </View>
        );
        
    }

    const CardSection = () => {
        if(Platform.OS == 'web'){
            return(
                <View style={whiteColor == true ? styles.cardWhite : styles.cardGrey}>  
                    <CardInfo/>
                </View>
            );
        }
        else
        {
            return(
                <View> 
                    <CardInfo/>     
                </View>
            );
        }
    }
    
    return (
        <View style={[styles.container, style]}>
           <CardSection/>
        </View> 
    );
}

export default HeaderCard;