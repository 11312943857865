
import React, { useContext, useCallback, useState, useEffect,Fragment} from 'react';
import { Text, Divider} from 'react-native-paper';
import { TouchableOpacity, View, ScrollView, FlatList, ActivityIndicator, Dimensions, Platform } from 'react-native'
import styles from './styles'
import DoorSVG from '../../resources/Tarjetas/door-open-fill.svg'
import CreditCardSVG from '../../resources/menu Lateral/CreditCard.svg'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import { AuthContext } from '../app/context';
import { RendicionContext } from '../../context/RendicionContext';


import AsyncStorage from '@react-native-async-storage/async-storage';


const TipoRendicion = () => {
    const { signOut } = React.useContext(AuthContext);
    const { setTipoRendicion } = React.useContext(RendicionContext);


    let styleHeight = Dimensions.get('window').height
    const navigation = useNavigation();
    const [selectedId, setSelectedId] = useState();    
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [textwarning, setTextwarning] = useState(false);

    const handleSignout = () => {
        if (Platform.OS == "web"){
            AsyncStorage.removeItem("@storage_Key").then(() => {
                window.location.reload();
            });
        }else{
            AsyncStorage.removeItem("@storage_Key").then(() => {
                signOut();
            });
        }
    }    

    function handleTipo (prop) {        
        var idTipoRendicion = prop.id
        setTipoRendicion(idTipoRendicion);

        if(idTipoRendicion == 1)
        {
            navigation.navigate('MisGastos');
        }
        else
        {
            navigation.navigate('OtrasRendiciones');
        }
    };

     //Llamada a la API
     useEffect(() => {
        async function fetchData() {
            const userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(API_URL + '/Gastos/GetTiposRendiciones', {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            const data = await response.json();

            setDatos(data);            
            setLoading(false);
        }
        fetchData();
    }, []);



    const renderTipo = ({ item }) => {             
        var id = item.idTipoRendicion;
        return (
            <View style={[styles.cardRendicion]}>                                            
                <TouchableOpacity style={[styles.fraction]} onPress={() => handleTipo({id})}>                                
                    <SvgFormatter SvgPath={CreditCardSVG}/>
                    <View style={{flexDirection:'column', marginLeft:20}}>                                                        
                        <Text style={styles.textTipoRendicion}>{item.descripcion}</Text>
                    </View>
                </TouchableOpacity>
            </View>
         );
    }

    return (
        <Fragment>          
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <FlatList  ListFooterComponent={ 
                <View style={[styles.container,{minHeight: styleHeight}]}>
                    <View style={styles.card}>
                        <TouchableOpacity onPress={handleSignout}>
                                { Platform.OS == "web" ? <img src={DoorSVG} style={{ alignSelf: 'center', width: 32, height: 32}}/> : 
                                <DoorSVG style={{alignSelf:'center'}}/> }
                        </TouchableOpacity>
                        <View>
                            <Text style={styles.title}>Seleccione tipo rendición</Text>
                        </View>
                        <View>
                            <Divider style={styles.horizontalLine} />
                            <View style={{marginTop:"3%"}}>
                                <ScrollView style={{alignSelf:'center'}} scrollIndicatorInsets={{ top: 1, bottom: 1 }}
                                        contentInsetAdjustmentBehavior="always">
                                        {loading ? <ActivityIndicator size="large" color="#00ff00" /> :
                                            <View style={{ minHeight: 250 }}> 
                                                <FlatList
                                                data={datos}
                                                renderItem={renderTipo}
                                                keyExtractor={tipo => `${tipo.IdTipoRendicion}`}
                                                extraData={selectedId} />                                                                                       
                                            </View>
                                        }
                                </ScrollView>                                
                            </View>
                        </View>
                        <View style={{alignItems: 'center'}}>
                            {textwarning ? <Text style={styles.textWarning}>Seleccione tipo rendición.</Text> : <></>}                                   
                        </View>
                                
                    </View>       
                </View>
                }/>
            </SafeAreaView>
        </Fragment>
      );
}
export default TipoRendicion;