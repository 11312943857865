import React, { useEffect, useState } from 'react'
import { Text, DataTable } from 'react-native-paper'
import { TouchableOpacity, View, ScrollView, ActivityIndicator, Linking, Platform, Share, TextInput } from 'react-native'
import styles from './styles'
import GreenApprovedSVG from '../../resources/Detalle de gasto/GreenApproved.svg'
import DesconocidoSVG from '../../resources/Detalle de gasto/BlueUnknown.svg'
import ListSVG from '../../resources/Detalle de gasto/ListSvg.svg'
import DollarSignSVG from '../../resources/Detalle de gasto/DollarSign.svg'
import CantidadSVG from '../../resources/Detalle de gasto/Ticket.svg'
import CalendarSVG from '../../resources/Detalle de gasto/CalendarGroup.svg'
import CarSVG from '../../resources/Detalle de gasto/CircleCar.svg'
import Close from '../../resources/Detalle de gasto/close.svg'
import { useNavigation } from '@react-navigation/native'
import PendingSVG from '../../resources/Estado/pending.svg'
import useFetch from '../../hooks/useFetch'
import { FileSystem } from 'react-native-unimodules';
import * as IntentLauncher from 'expo-intent-launcher';
import LoadScreen from '../../components/LoadScreeen';
import HeaderCard from '../../components/header-tarjeta'
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import AsyncStorage from '@react-native-async-storage/async-storage';


const DetalleRendiciones = (rendicionHeredada) => {
    const [image, setImage] = useState('');
    const [loadingImg, setLoadingImg] = useState(true);
    const [downloadingImg, setDownloadingImg] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const [comprobantes, setComprobantes] = useState([]);
    const rendicion = rendicionHeredada.route.params;
    const navigation = useNavigation();
    const key = rendicion.key;
    const estado = rendicion.estado;
    const estadoFull = rendicion.estadoFull;
    const numeroRendicion = rendicion.numeroRendicion;
    const nombreEmpresa = rendicion.nombre;
    const moneda = rendicion.moneda;
    const importe = rendicion.importe;
    const fecha = rendicion.fecha;
    const { loading, datos } = useFetch(API_URL + '/Gastos/detalles/' + rendicion.id)
    var TextPendiente = <Text />
    var estadoSVG = <SvgFormatter SvgPath={PendingSVG} />

    const descargarComprobante = async (data) => {
        setDownloadingImg(true);
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/DescargarComprobanteByGastoId?id=" + data.idGasto + "&comprobantesIds=" + data.idComprobanteGasto;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            }
        });

        if (Platform.OS == "web") {
            response.arrayBuffer()
                .then(response => {
                    var re = /(?:\.([^.]+))?$/;
                    var extension = data.extension != undefined ? "zip" : re.exec(data.tituloComprobante)[1];
                    const arr = new Uint8Array(response);
                    const blob = new Blob([arr], { type: extension == 'zip' ? 'application/zip' : extension == "pdf" ? 'application/pdf' : 'image/' + extension });
                    const blobURL = URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = blobURL;
                    a.download = data.tituloComprobante != undefined ? data.tituloComprobante : "Comprobantes";
                    a.click();
                    window.URL.revokeObjectURL(blobURL);
                    setDownloadingImg(false);
                }).catch(error => {
                    console.log(error); //TODO: Agregar mensaje de error
                });
        }
        else {
            if (data.tituloComprobante == undefined || data.tituloComprobante == "")
                data.tituloComprobante = "Comprobantes.zip"
            FileSystem.downloadAsync(
                url,
                FileSystem.documentDirectory + data.tituloComprobante, {
                headers: {
                    "Authorization": `Bearer ${userToken}`,
                    'Accept': '*/*'
                }
            }).then(({ uri }) => {
                Linking.canOpenURL(uri).then(supported => {
                    if (supported) {
                        FileSystem.getContentUriAsync(uri)
                            .then(cUri => {
                                IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
                                    data: cUri,
                                    flags: 1,
                                });
                                setDownloadingImg(false);
                            });
                    }
                    else {
                        console.log("No soportado")
                    }
                })
            });
        }
    }

    const getComprobante = async (rendicionId) => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        let url = API_URL + "/Gastos/comprobanteDelGasto/" + rendicionId

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
        });
        await response.json().then((data) => {
            let comprobante = data.comprobante;
            setIsPdf(data.esPdf);
            setComprobantes(data.comprobantes);
            setImage(comprobante != null ? comprobante : '');
            setLoadingImg(false)
        });
    }

    useEffect(() => {
        getComprobante(rendicion.id);
    }, []);

    switch (estado) {
        case 'Pendiente':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estadoFull}</Text>
            break;
        case 'Anulada':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;
        case 'Rendido':
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#008B47' }]}>{estadoFull}</Text>
            break;
        case 'Desconocido':
            estadoSVG = <SvgFormatter SvgPath={DesconocidoSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#2759CB' }]}>{estadoFull}</Text>
            break;
        default:
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;
    }

    const renderDesgloseWeb = (item) => (
        <View style={styles.cardDesgloseWeb}>
            <View style={{ width: '25%' }}><Text style={[styles.textCardDesgloseWeb]}>{item.categoriaDes}</Text></View>
            <View style={{ width: '25%' }}><Text style={[styles.textCardDesgloseWeb]}>{item.centroCostoDes}</Text></View>
            {item.fechaDesglose != null ? <View style={{ width: '25%' }}><Text style={[styles.textCardDesgloseWeb]}>{item.fechaDesglose.replace('T00:00:00', '')}</Text></View>
                : <></>}
            {rendicion.tipoGasto.mideEnCantidad ?
                <View><Text style={[styles.textCardDesgloseWeb]}>{item.importe}</Text></View>
                :
                <View><Text style={[styles.textCardDesgloseWeb]}>{rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text></View>
            }

        </View>
    );

    const renderDesglose = (item) => (
        <View style={styles.cardDesglose}>
            <Text style={[styles.textCardDesglose]}>{item.categoriaDes}</Text>
            <Text style={[styles.textCardDesglose]}>{item.centroCostoDes}</Text>
            {item.fechaDesglose != null ? <Text style={[styles.textCardDesglose]}>{item.fechaDesglose.replace('T00:00:00', '')}</Text>
                : <></>}
            {rendicion.tipoGasto.mideEnCantidad ?
                <Text style={[styles.textCardDesglose]}>{item.importe}</Text>
                :
                <Text style={[styles.textCardDesglose]}>{rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text>
            }
        </View>
    );

    const getComprobantesGastosId = () => {
        var elements = comprobantes.map(a => a.idComprobanteGasto);
        var ids = [];
        elements.forEach((e, i) => {
            if (i == 0 )
                ids.push(e)
            else
                ids.push("&comprobantesIds=" + e)
        });

        return { idGasto: rendicion.id, idComprobanteGasto: ids.join(""), extension: "zip" };
    }

    return (
        loading ? <LoadScreen /> :
            <ScrollView scrollIndicatorInsets={{ top: 1, bottom: 1 }}
                contentInsetAdjustmentBehavior="always">
                <View style={styles.container}>
                    <View style={styles.card}>
                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity onPress={() => navigation.pop()} >
                                {Platform.OS == "web" ? <img src={Close} style={{ alignSelf: 'center' }} /> : <Close />}
                            </TouchableOpacity>
                            {Platform.OS !== 'web' ? <HeaderCard style={{ height: 30, marginTop: 30 }} /> : <></>}
                        </View>

                        <View>
                            <Text style={styles.title}>
                                {nombreEmpresa}
                            </Text>
                            {Platform.OS == 'web' ?
                                <HeaderCard style={{ justifyContent: 'center', alignItems: 'center' }} whiteColor={false} />
                                :
                                <></>
                            }
                        </View>
                        <View style={styles.allCategories}>
                            <View style={styles.hr} />
                            <View style={styles.fraction}>
                                {estadoSVG}
                                <View style={styles.textFraction}>
                                    <Text style={styles.categories}>Estado</Text>
                                    {TextPendiente}
                                    {rendicion.numeroRendicion == null || "" ? <></> :
                                        <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{rendicion.numeroRendicion}</Text>}
                                </View>
                            </View>
                            <View style={styles.hr} />
                            {rendicion.tipoGasto.mideEnCantidad ?
                                <View style={styles.fraction}>
                                    <SvgFormatter SvgPath={CantidadSVG} style={styles.icons} />
                                    <View style={styles.textFraction}>
                                        <Text style={styles.categories}>Total</Text>
                                        <Text style={styles.simpleText}>{importe}</Text>
                                    </View>
                                </View>
                                :
                                <View style={styles.fraction}>
                                    <SvgFormatter SvgPath={DollarSignSVG} style={styles.icons} />
                                    <View style={styles.textFraction}>
                                        <Text style={styles.categories}>Total</Text>
                                        <Text style={styles.simpleText}>{moneda == 'USD' ? 'USD' : '$'}{importe}</Text>
                                    </View>
                                </View>
                            }
                            {rendicion.vehiculo != null && rendicion.vehiculo != undefined ?
                                <View>
                                    <View style={styles.hr} />
                                    <View style={styles.fraction}>
                                        <SvgFormatter SvgPath={CarSVG} style={styles.icons} />
                                        <View style={styles.textFraction}>
                                            <Text style={styles.categories}>Vehículo</Text>
                                            <Text style={styles.simpleText}>{rendicion.vehiculo.descripcion + " / " + rendicion.vehiculo.dominio}</Text>
                                        </View>
                                    </View>
                                </View>
                                : <></>
                            }

                            <View style={styles.hr} />
                            <View style={styles.fraction}>
                                <SvgFormatter SvgPath={ListSVG} style={styles.icons} />
                                <View style={{ width: '90%' }}>
                                    <Text style={styles.categories}>Desglose</Text>
                                    {Platform.OS == "web" ?
                                        <View>{rendicion.desgloseGastos.map(r => renderDesgloseWeb(r))}</View> :
                                        <View>{rendicion.desgloseGastos.map(r => renderDesglose(r))}</View>
                                    }
                                </View>
                            </View>
                            <View style={styles.hr} />
                            <View style={styles.fraction}>
                                <SvgFormatter SvgPath={CalendarSVG} style={styles.icons} />
                                <View style={styles.textFraction}>
                                    <Text style={styles.categories}>Fecha</Text>
                                    <Text style={styles.simpleText}>{fecha}</Text>
                                </View>
                            </View>
                            <View style={styles.hr} />
                        </View>
                        <View style={styles.inputSection}>
                            <Text style={styles.categories}>Comentarios</Text>
                            {loading ? <></> :
                                <Text style={styles.textInput}>
                                    {datos.comentario}
                                </Text>}
                        </View>
                        {comprobantes != undefined && comprobantes.length > 1 ?
                            <View style={{ display: 'flex', alignItems: 'flex-end', paddingRight: '5%' }}>
                                <DataTable.Cell><TouchableOpacity
                                    onPress={() => {
                                        var data = getComprobantesGastosId()
                                        descargarComprobante(data);
                                    }}
                                    style={[styles.btnSiguiente, { backgroundColor: '#0a834b' }]}
                                >
                                    <Text style={styles.textSiguiente}>Descargar Comprobantes</Text>
                                </TouchableOpacity></DataTable.Cell>
                            </View> : <View></View>
                        }
                        <View>
                            { loadingImg ? <LoadScreen/> :
                                <DataTable>
                                    <DataTable.Header>
                                        <DataTable.Title>Título</DataTable.Title>
                                        <DataTable.Title>Descripción</DataTable.Title>
                                        <DataTable.Title></DataTable.Title>
                                    </DataTable.Header>
                                    
                                    {comprobantes != undefined && comprobantes.length > 0 ? comprobantes.map((data, index) => {
                                        return (
                                            data.tituloComprobante == null && data.comprobante == null ? <></> : 
                                            <View id={"idComprobanteGasto-" + data.idComprobanteGasto} key={"keyComprobanteGasto-" + data.idComprobanteGasto}>
                                                <DataTable.Row key={"row-" + index}>
                                                    <DataTable.Cell>
                                                        <TextInput
                                                            label="Título"
                                                            value={data.tituloComprobante}
                                                            editable={false}
                                                            selectTextOnFocus={false}
                                                        />
                                                    </DataTable.Cell>
                                                    <DataTable.Cell>
                                                        <TextInput
                                                            label="Descripción"
                                                            value={data.descripcionComprobante}
                                                            editable={false}
                                                            selectTextOnFocus={false}
                                                        />
                                                    </DataTable.Cell>
                                                    <DataTable.Cell><TouchableOpacity
                                                        onPress={() => { descargarComprobante(data) }}
                                                    >
                                                        <Text>Descargar</Text>
                                                    </TouchableOpacity></DataTable.Cell>
                                                </DataTable.Row>
                                            </View>
                                        )
                                    }) : <View></View>
                                    }
                                </DataTable>
                            }
                            { downloadingImg ? <Text style={{margin: 'auto'}}>Descargando <ActivityIndicator size="small" color="#00ff00" /></Text> : <></>}
                        </View>
                    </View>
                </View>
            </ScrollView>
    );
}

export default DetalleRendiciones;  