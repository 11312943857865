
import React, { useContext, useEffect } from 'react'
import { Text } from 'react-native-paper'
import { View, Platform, TouchableOpacity } from 'react-native'
import styles from './styles'
import GreenApprovedSVG from '../../resources/Detalle de gasto/GreenApproved.svg'
import DesconocidoSVG from '../../resources/Detalle de gasto/BlueUnknown.svg'
import PendingSVG from '../../resources/Estado/pending.svg'
import Trash from '../../resources/Detalle de gasto/trash.svg'
import Send from '../../resources/Detalle de gasto/send.svg'
import { Tooltip } from 'react-native-elements';
import { API_URL } from '../../config/constants';
import AsyncStorage from '@react-native-async-storage/async-storage';


import SvgFormatter from '../../../svgFormatter';
import { useNavigation } from '@react-navigation/native';

const ResumenRendicion = (props) => {

    const navigation = useNavigation();

    const key = props.datosRendicion.id;
    const estado = props.datosRendicion.estado;
    const estadoFull = props.datosRendicion.estadoFull;
    const numeroRendicion = props.datosRendicion.numeroRendicion;
    const nombreEmpresa = props.datosRendicion.titulo;
    const moneda = props.datosRendicion.moneda;
    const precio = props.datosRendicion.importe;
    const fecha = props.datosRendicion.fecha;
    var TextPendiente = <Text />
    var estadoSVG = <PendingSVG />

    switch (estado) {
        case 'Pendiente':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estadoFull}</Text>
            break;
        case 'Anulada':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;
        case 'Rendido':
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#008B47' }]}>{estadoFull}</Text>
            break;
        case 'Desconocido':
            estadoSVG = <SvgFormatter SvgPath={DesconocidoSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#2759CB' }]}>{estadoFull}</Text>
            break;
        default:
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;

    }

    return (
    <View>
        <View style={styles.cardRendicion}>
            {estadoSVG}
            {
                Platform.OS !== 'web' ?
                    <View style={{ width: 100 }}>
                        <Tooltip backgroundColor='#008B47' popover={<Text style={[styles.tooltipText]}>{nombreEmpresa}</Text>}>
                            <Text numberOfLines={1} ellipsizeMode='tail' style={[styles.textCardRendicion, { color: '#353D4A' }]}>{nombreEmpresa}</Text>
                        </Tooltip>
                        {TextPendiente}
                        {numeroRendicion == null || "" ? <></> : 
                                    <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{numeroRendicion}</Text>}
                    </View>
                    :
                    <View>
                        <Text style={[styles.textCardRendicion, { color: '#353D4A' }]}>{nombreEmpresa}</Text>
                        {TextPendiente}
                        {numeroRendicion == null || "" ? <></> : 
                                    <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{numeroRendicion}</Text>}
                    </View>
            }            
            <View>
                <Text style={[styles.textCardRendicion, { color: '#353D4A' }]}>
                    {moneda == 'USD' ? 'USD$' : '$'}{precio}
                </Text>
                <Text style={[styles.textCardRendicion2, { color: '#B1B5BE' }]}>{fecha}</Text>
            </View>
        </View>            
  
    </View>
    );
}

export default ResumenRendicion;