import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';


const useFetch = (url, headers) => {
    

    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState();

    const fetchData = async () => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        const response = await fetch(url, {
            headers: {
                "Authorization": `Bearer ${userToken}`
            }
        });
        const data = await response.json()
        setDatos(data)
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    },[])

    return { loading, datos}
}

export default useFetch
