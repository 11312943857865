import React, { useContext, useState } from 'react';
import { Text, Divider} from 'react-native-paper';
import { TouchableOpacity, View, ActivityIndicator, ScrollView, Platform} from 'react-native'
import styles from './styles'
import Close from '../../resources/Detalle de gasto/close.svg'
import EllipseSVG from '../../resources/Estado/ellipse.svg'
import EllipseChekSVG from '../../resources/Estado/ellipseChek.svg'
import HeaderCard from '../../components/header-tarjeta'
import ResumenRendicion from '../../components/ResumenRendicion';
import {useNavigation} from '@react-navigation/native'
import useFetch from '../../hooks/useFetch'
import { API_URL } from '../../config/constants';

import SvgFormatter from '../../../svgFormatter';

import { RendicionContext } from '../../context/RendicionContext';
import { LegajoContext } from '../../context/LegajoContext';

const Estado = (rendicionHeredada) => {
    
    const { tipoRendicion } = useContext(RendicionContext);
    const {legajo} = useContext(LegajoContext);

    var esNuevaRendicion = true;
    var rendicion = {
        id: null,
        titulo: '',
        estado: '',
        estadoFull: '',
        numeroRendicion: '',
        importe: '',
        moneda: '',
        fecha: '',
        nombreEmpresa: '',
        estadoAnterior: '',
        esNueva: true,
        DATA:
        {
            tipoGastoId: '',
            desgloseGasto: [],
            comprobante: '',
            comprobanteEsPdf: false,
            comentario: ''
        },
    }

    if(rendicionHeredada.route.params != null && rendicionHeredada.route.params != undefined)
    {        
        esNuevaRendicion = false;
        rendicion = {
            id: rendicionHeredada.route.params.id,
            titulo: rendicionHeredada.route.params.titulo,
            estado: rendicionHeredada.route.params.estado,
            estadoFull: rendicionHeredada.route.params.estadoFull,
            numeroRendicion: rendicionHeredada.route.params.numeroRendicion,
            importe: rendicionHeredada.route.params.importe,
            moneda: rendicionHeredada.route.params.moneda,
            fecha: rendicionHeredada.route.params.fecha,
            nombreEmpresa: rendicionHeredada.route.params.titulo,
            estadoAnterior: rendicionHeredada.route.params.estado,
            esNueva: false,
            DATA:
            {
                tipoGastoId: '',
                desgloseGasto: [],
                comprobante: '',
                comprobanteEsPdf: false,
                comentario: ''
            },
        }
    }    
    
    const navigation = useNavigation();
    const [selectedId, setSelectedId] = useState('0');
    const [tipoGastoEnCantidad, setGastoEnCantidad] = useState(false);

    const SelectItem = (prop) => {
        setSelectedId(prop.idTipoGasto);
        setGastoEnCantidad(prop.mideEnCantidad);
    }

    //Llamada a la API
    const { loading, datos } = useFetch(API_URL + '/Gastos/GetTiposGastos/' + (tipoRendicion)+ '/' + (legajo))    
    const handleBuscador = () => {
        
        rendicion.DATA.tipoGastoId = selectedId;
        rendicion.estado = handleEstado(selectedId);        
        rendicion.gastoEnCantidad = tipoGastoEnCantidad;

        if(!esNuevaRendicion)
        {            

            if (selectedId == 1 || selectedId == 3) {
                rendicion.datosRendicion = {
                    id: rendicionHeredada.route.params.id,
                    titulo: rendicionHeredada.route.params.titulo,
                    moneda: rendicionHeredada.route.params.moneda,
                    importe: rendicionHeredada.route.params.importe,
                    fecha: rendicionHeredada.route.params.fecha,
                    estado: rendicionHeredada.route.params.estado,
                    estadoFull: rendicionHeredada.route.params.estadoFull,
                    DATA:
                    {
                        tipoGastoId: rendicion.DATA.tipoGastoId,
                        comprobante: '',
                        comprobanteEsPdf: false,
                        comentario: ''
                    },
                }
                navigation.navigate('Comentario', rendicion);
                return;
            }

            navigation.navigate('GestionDesglose', rendicion);
        }
        else
        {
            // if (selectedId == 1 || selectedId == 3)
            // {
            //     navigation.navigate('Comentario', rendicion);
            //     return; 
            // }
            if(rendicion.gastoEnCantidad == true)
            {
                navigation.navigate('Vehiculo', rendicion);   
            }
            else
            {
                navigation.navigate('NuevaRendicion', rendicion);
            }                        
        }
    }

    function handleEstado(estadoId) {
        switch (estadoId) {
            case 1:
                return "Rendido";
            case 2:
                return "Rendido";
            case 3:
                return "Desconocido";
            default:
                return "Rendido"
        }
    }

    return (
        <ScrollView>
            <View style={styles.container}>
                <View style={styles.card}>
                    <View style={{flexDirection:'row'}}>
                        <TouchableOpacity onPress={() => navigation.pop()}>
                        { Platform.OS == "web" ? <img src={Close} style={{ alignSelf: 'center' }}/> : <Close/> }
                        </TouchableOpacity>
                        {Platform.OS !== 'web' ? <HeaderCard style={{height:30}} whiteColor={false}/> : <></> }
                    </View>

                    <View style={{flexDirection:'row'}}>
                        <View>
                            <Text style={styles.title}>Tipo de Rendicion</Text>
                            <Text style={styles.categories}>Generar Rendición</Text>
                        </View>
                        {Platform.OS == 'web' ?
                            <View style={{marginLeft:'auto'}}>
                            <HeaderCard />
                            </View> : <></>} 
                    </View>
                    {esNuevaRendicion ? <></>:
                        <ResumenRendicion datosRendicion={rendicion} />
                    }                    
                    <Divider style={styles.horizontalLine} />

                    {loading ? <ActivityIndicator size="large" color="#00ff00" /> :
                        <View>

                            {datos.map(estado => (
                                selectedId == estado.idTipoGasto ?
                                    <TouchableOpacity style={[styles.fraction, { backgroundColor: '#E2EEE8' }]} onPress={() => SelectItem(estado)} key={estado.idTipoGasto}>
                                        <Text style={[styles.greenText, { color: '#0A834B' }]}>{estado.descripcion}</Text>
                                        <View style={styles.textFraction}>
                                            <SvgFormatter SvgPath={EllipseChekSVG} style={styles.icons} />
                                        </View>
                                    </TouchableOpacity> :
                                    <TouchableOpacity style={[styles.fraction, { backgroundColor: '#fff' }]} onPress={() => SelectItem(estado)} key={estado.idTipoGasto}>
                                        <Text style={[styles.greenText, { color: '#353D4A' }]}>{estado.descripcion}</Text>
                                        <View style={styles.textFraction}>
                                            <SvgFormatter SvgPath={EllipseSVG} style={styles.icons} />
                                        </View>
                                    </TouchableOpacity>
                            ))}

                        </View>}
                    <View>
                        <TouchableOpacity style={styles.btnSiguiente} onPress={()=>handleBuscador()} >
                            <Text style={styles.textSiguiente} >Siguiente</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
}

export default Estado;