import React, { useContext, useCallback, useState, useEffect } from 'react'
import { Text } from 'react-native-paper';
import { TouchableOpacity, View, TextInput, ScrollView, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Comentario/arrow.svg'
import ResumenRendicion from '../../components/ResumenRendicion'
import { useNavigation } from '@react-navigation/native'
//import { AsyncStorage } from 'react-native'
import { useNetInfo } from "@react-native-community/netinfo"
import { API_URL } from '../../config/constants';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import SvgFormatter from '../../../svgFormatter';
import KeyboardDone from '../../../keyboardDone';
import HeaderCard from '../../components/header-tarjeta'
import { LegajoContext } from '../../context/LegajoContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import LoadScreen from '../../components/LoadScreeen';

const Comentario = (rendicionHeredada) => {
    const { legajo } = useContext(LegajoContext);
    const inputAccessoryViewID = 'done';
    const [inputTexto, guardarInputTexto] = useState('');
    const navigation = useNavigation();
    const { isConnected } = useNetInfo();
    const [saving, setSaving] = useState(false);
    const rendicion = rendicionHeredada.route.params.datosRendicion;
    rendicionHeredada.route.params.DATA == null ? rendicion.tipoGastoId = rendicionHeredada.route.params.datosRendicion.DATA.tipoGastoId : rendicionHeredada.route.params.DATA.tipoGastoId;

    const toastConfig = {
        'exito': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toast}>
                    <SvgFormatter SvgPath={GreenBackgroundApprovedSVG} style={{ marginRight: 10 }} />
                    <Text style={styles.toastText}>Tu rendición fue realizada {"\n"}con éxito.</Text>
                </View>
            </View>
        ),
        'error': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toastError}>
                    <Image source={require('../../resources/MisGastos/GreenBackgroundFailedPNG.png')} style={{ marginRight: 10, width: 30, height: 30 }} />
                    <Text style={styles.toastText}>Error al realizar rendición.</Text>
                </View>
            </View>
        ),
        'info': () => { },
        'any_custom_type': () => { }
    }
    const showToast = (isOk) => {
        switch (isOk) {
            case true:
                Toast.show({ type: 'exito' });
                return;
            case false:
                Toast.show({ type: 'error' });
                return;
        }
    }

    const handleMisGastosNav = useCallback(async () => {
        if (rendicion.tipoGastoId != 2) {
            rendicion.DATA = rendicionHeredada.route.params.DATA;
            rendicion.DATA.comentario = inputTexto;
            rendicion.DATA.categoriaId = 0;
            if (inputTexto.trim() != "") {
                if (isConnected) {
                    await fetchRendicion(rendicion.id).then(async (res) => {
                        if (res.ok != null && res.status != null) {
                            console.log(res.status.toString());
                            await setStatus(res.status.toString());
                            navigation.navigate('MisGastos');
                        }
                    });
                }
                else {
                    guardarDatosStorage(rendicion);
                    navigation.navigate('MisGastos');
                }
            }
            else { console.log("No hay comentario"); }
        }
        else {
            rendicion.DATA.comentario = inputTexto;
            if (isConnected) {
                await fetchRendicion(rendicion.id).then(async (res) => {
                    if (res.ok != null && res.status != null) {
                        console.log(res.status.toString());
                        await setStatus(res.status.toString());
                        navigation.navigate('MisGastos');
                    }
                });
            }
            else {
                guardarDatosStorage(rendicion);
                navigation.navigate('MisGastos');
            }
        }

    });

    const handleNuevaRendicionNav = useCallback(async () => {
        rendicion.DATA.comentario = inputTexto;
        if (isConnected) {
            setSaving(true);
            await saveRendicion().then(async (res) => {
                setSaving(false);
                if (res.ok != null && res.status != null) {
                    console.log(res.status.toString());
                    await setStatus(res.status.toString());
                    navigation.navigate('OtrasRendiciones');
                }
            });
        }
        else {
            //guardarDatosStorage(rendicion);
            //navigation.navigate('OtrasRendiciones');

            showToast(false);
        }

    });

    const setStatus = async (status) => {
        try {
            await AsyncStorage.setItem(
                'STATUS',
                status
            );
        } catch (error) {
            console.log(error)
        }
    }
    const guardarDatosStorage = async (rendicion) => {
        var holdStorage = [];
        var rendicionesExistentes;
        const DATA =
        {
            id: rendicion.id,
            rendicionId: rendicion.id,
            tipoGastoId: rendicion.DATA.tipoGastoId,
            comprobante: rendicion.DATA.comprobante,
            comentario: rendicion.DATA.comentario,

            desgloseGasto: rendicion.DATA.desgloseGasto
        }

        rendicionesExistentes = await AsyncStorage.getItem('rendiciones');
        rendicionesExistentes ? holdStorage = JSON.parse(rendicionesExistentes) : holdStorage = [];

        console.log(DATA);
        holdStorage.push(DATA);
        await AsyncStorage.setItem("rendiciones", JSON.stringify(holdStorage));
    }

    const fetchRendicion = async (id) => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/rendicion/" + id;
        rendicion.DATA.estado = rendicion.estado;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify(rendicion.DATA)
        });
        const status = response.status;
        return response;
    }

    const saveRendicion = async (id) => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/agregar/";
        rendicion.DATA.estado = rendicion.estado;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify({
                TipoGastoId: rendicion.DATA.tipoGastoId,
                ImporteTotal: rendicion.importe,
                FechaGasto: rendicion.fecha,
                Comentario: rendicion.DATA.comentario,
                Comprobante: rendicion.DATA.comprobante,
                Comprobantes: rendicion.DATA.comprobantes,
                ComprobanteEsPdf: rendicion.DATA.comprobanteEsPdf,
                DesglosesGasto: rendicion.DATA.desgloseGasto,
                Legajo: legajo,
                IdVehiculo: rendicion.DATA.idVehiculo
            })
        });
        const status = response.status;
        return response;
    }

    return (
        <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
            <View style={styles.container}>
                <View style={styles.card}>
                    <View style={{ flexDirection: 'row' }}>
                        <TouchableOpacity onPress={() => navigation.pop()}>
                            {Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center' }} /> : <ArrowSVG />}
                        </TouchableOpacity>
                        {Platform.OS !== 'web' ? <HeaderCard style={{ height: 30 }} whiteColor={false} /> : <></>}
                    </View>

                    <View style={{ flexDirection: 'row' }}>
                        <View>
                            <Text style={styles.title}>Agregar Comentario</Text>
                            <Text style={styles.categories}>Generar Rendición</Text>
                        </View>
                        {Platform.OS == 'web' ?
                            <View style={{ marginLeft: 'auto' }}>
                                <HeaderCard />
                            </View> :
                            <></>
                        }
                    </View>
                    <ResumenRendicion datosRendicion={rendicion} />
                    <View style={styles.inputSection}>
                        <Text style={styles.categories}>Comentarios</Text>
                        <TextInput numberOfLines={14} maxLength={500} multiline={true}
                            style={styles.textInput}
                            onChangeText={texto => guardarInputTexto(texto)}
                            inputAccessoryViewID={inputAccessoryViewID}>
                        </TextInput>
                        {rendicion.tipoGastoId != 2 && inputTexto.trim() === "" && !rendicionHeredada.route.params.datosRendicion.esNueva ? <Text style={styles.warning}>Debe Agregar un comentario.</Text> :
                            <></>}
                    </View>
                    <KeyboardDone inputAccessoryViewID={inputAccessoryViewID} />

                    <View>
                        {rendicionHeredada.route.params.datosRendicion.esNueva ?
                            <View>
                                {saving ?
                                    <LoadScreen />
                                    :
                                    <TouchableOpacity style={styles.btnSiguiente} onPress={() => handleNuevaRendicionNav()}>
                                        <Text style={styles.textSiguiente}>Finalizar rendición</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                            :
                            <TouchableOpacity style={styles.btnSiguiente} onPress={() => handleMisGastosNav()}>
                                <Text style={styles.textSiguiente}>Finalizar rendición</Text>
                            </TouchableOpacity>
                        }
                    </View>
                </View>
            </View>
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
        </KeyboardAwareScrollView>
    );
}

export default Comentario;