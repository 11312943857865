import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarContainer: {
        borderColor: '#0A834B',
        width: 280,
        height: 310,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
    },
    avatar: {
        width: 280,
        height: 310,
        borderRadius: 20,
    },
    textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    btnSiguiente: {
        padding: 12,
        marginTop: 10,
        borderRadius: 10,
    },
    containerTicketSubido: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
    },
    agregarComprobante: {
        marginTop: 15,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textTicketSubido: {
        color: '#0A834B',
        textAlign: 'center',
        marginHorizontal: 10,
    },
    tableContainer: {
        flex: 1,
        padding: 16,
        paddingTop: 30,
        backgroundColor: '#fff'
    },
    head: {
        height: 40,
        backgroundColor: '#f1f8ff'
    },
    text: {
        margin: 6
    },
    warning: {
        fontSize: 14,
        color: '#FF0000',
        alignSelf: 'center',
        justifyContent: 'center',
        marginTop: 50,
        fontFamily: 'Gotham-Bold'
    },
    cardComprobante: {
        display: 'flex',
        marginVertical: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    fileName: {
        padding: 7,
        borderColor: '#B1B5BE4D',
        borderWidth: 1,
        fontWeight: '500',
        height: 30,
        marginBottom: 10,
        textAlignVertical: 'top'
    },
    textInput: {
        padding: 7,
        borderColor: '#B1B5BE4D',
        borderWidth: 1,
        fontWeight: '500',
        height: 50,
        textAlignVertical: 'top'
    },
});
export default styles;