import { StyleSheet, Platform } from 'react-native';
import { getStatusBarHeight } from 'react-native-status-bar-height';

let height = 30;

if (Platform.OS != 'web')
    height = 15

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#008B47',
    },
    header: {
        backgroundColor: '#008B47',
        justifyContent: 'space-between',
        elevation: 0,
        marginTop: 18 + getStatusBarHeight(),
    },

    panel: {
        backgroundColor: '#FAFAFA',
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
        justifyContent: "center",
        marginTop: height,
    },
    fechas: {
        fontSize: 20,
        color: '#FAFAFA'
    },
    panelGastos: {
        fontSize: 30,
        color: '#FAFAFA',
        fontFamily: 'Gotham',
        marginTop: 25,
        marginLeft: 30
    },
    panelGastosWeb: {
        fontSize: 30,
        color: '#FAFAFA',
        fontFamily: 'Gotham',
        marginTop: 70,
        marginLeft: 30
    },
    sandwich: {
        marginLeft: 50,
    },
    labelStyle: {
        color: '#FAFAFA',
        fontSize: 20,
        backgroundColor: '#008B47',
        justifyContent: 'flex-start',
    },
    DropDownPickerStyle: {
        backgroundColor: '#008B47',
        width: 170,
        borderColor: '#008B47',        
        marginLeft: 30
    },
    dropDownStyle: {
        backgroundColor: '#008B47',
        borderColor: '#008B47',
        width: 165,
        marginLeft: 30        
    },
    itemStyle: {
        justifyContent: 'flex-start'
    },
    toast: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        flexDirection: 'row',
        borderRadius: 12,
        width: '100%',
        padding: 10,
    },
    toastError: {
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        flexDirection: 'row',
        borderRadius: 12,
        width: '100%',
        padding: 10,
    },
    toastText: {
        color: '#0A834B',
        fontSize: 17,
        fontWeight: 'bold',
    },
    textSinGastos: {
        paddingVertical: 200,
        paddingHorizontal: 70,
        fontSize: 30,
        color: '#d3d3d3',
        alignContent: 'center'
    },
    textSinPeriodo: {
        padding: 10,
        fontSize: 20,
        color: 'black',
        fontFamily: 'Gotham',
        alignContent: 'center'
    }
});

export default styles;
