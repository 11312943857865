import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        marginLeft: 20,
        marginTop: 42,
        marginRight: 20,
        flex: 1,

        alignContent: 'flex-start',
    },
    closebttn: {
        marginBottom: 69,

    },
    titulo: {
        color: '#353D4A',
        fontWeight: '500',
        fontFamily: 'Gotham'

    },
    subcontainer: {
        flexDirection: "row",

        marginTop: 20,
        alignContent: 'flex-start',
    },
    textNombre: {
        fontSize: 27,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 'bold',
        fontFamily: 'Gotham'

    },
    textNumero: {
        fontSize: 14,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        fontWeight: '500'
    },
    horizontalLine: {
        borderBottomColor: '#E7E9EB',
        borderBottomWidth: 1,
        marginRight: 80
    },
    cambiarPantalla: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        backgroundColor: '#008b47',
        marginTop: 20,
        borderRadius: 10,
    },
    cerrarSesion: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5,
        backgroundColor: '#FF0000',
        marginTop: 40,
        borderRadius: 10,
    },
    cerrarSesionText: {
        fontFamily: "Gotham-Bold",
        color: 'white'
    }
});

export default styles;
