
import React, { useState } from 'react'
import styles from './styles'
import { TouchableOpacity, View, FlatList, Dimensions, SafeAreaView, Platform } from 'react-native'
import LookupSVG from '../../resources/Listar Gastos/WhiteLookUp.svg'
import Close from '../../resources/Detalle de gasto/close.svg'
import DropdownSVG from '../../resources/Buscar/dropdownSVG.svg'
import DropDownPicker from 'react-native-dropdown-picker'
import { Searchbar } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import ResumenRendicion from '../../components/ResumenRendicion'
import KeyboardDone from '../../../keyboardDone';
import SvgFormatter from '../../../svgFormatter';


const BuscarGastos = (rendiciones) => {
    const rendicionesDATA = rendiciones.route.params;
    let styleHeight = Dimensions.get('window').height;
    const inputAccessoryViewID = 'done';
    const navigation = useNavigation();
    const [searchQuery, setSearchQuery] = React.useState([]);

    const searchFilterFunction = text => {
        const newData = rendicionesDATA.filter(item => {
            const itemData = `${item.titulo.toUpperCase()}`;
            const textData = text.toUpperCase();
            return itemData.indexOf(textData) > -1;
        });
        setSearchQuery(newData);
    };

    const renderRendicion = ({ item }) => (
        <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', item)} >
            <ResumenRendicion datosRendicion={item} />
        </TouchableOpacity>
    );

    return (

        <View style={[styles.container, { minHeight: styleHeight }]}>
            <View style={styles.holder}>
                <View style={styles.header}>
                    <TouchableOpacity style={styles.sandwich} onPress={() => navigation.pop()}>
                        <SvgFormatter SvgPath={Close} />
                    </TouchableOpacity>
                    {/*<DropDownPicker
                        items={[
                            { label: 'Ascendente', value: 'Ascendente' },
                            { label: 'Descendente', value: 'Descendente' },
                        ]}

                        arrowColor={'#90949D'}
                        arrowSize={20}
                        defaultValue=''
                        containerStyle={{ height: 40 }}
                        style={{ backgroundColor: '#EBECEE', width: 150 }}
                        itemStyle={{
                            justifyContent: 'flex-start'
                        }}
                        placeholder='Ordenar por'
                        labelStyle={{ color: '#90949D', fontWeight: 'bold' }}
                        icon={() => <SvgFormatter SvgPath={LookupSVG} />}
                        searchablePlaceholderTextColor='#90949D'
                        searchablePlaceholder='Ordenar por'
                        dropDownStyle={{ backgroundColor: '#EBECEE' }}
                        customArrowDown={() => <SvgFormatter SvgPath={DropdownSVG} />}
                        customArrowUp={() => <SvgFormatter SvgPath={DropdownSVG} />}
                    />*/}
                </View>
                <Searchbar
                    placeholder=" Buscar gasto"
                    placeholderTextColor='#c0c0c0'
                    selectionColor='green'
                    autoFocus={true}
                    inputAccessoryViewID={inputAccessoryViewID}
                    style={{
                        backgroundColor: '#FAFAFA',
                        elevation: 0,
                        zIndex: 1
                    }}
                    inputStyle={{fontWeight: 'bold',
                        fontSize: 25}}
                    onChangeText={searchFilterFunction}

                    icon={() => <SvgFormatter SvgPath={LookupSVG} />}
                    autoCorrect={false}
                />
                <FlatList
                    data={searchQuery}
                    renderItem={renderRendicion}
                    keyExtractor={rendi => rendi.key}
                />
            </View>
            <KeyboardDone inputAccessoryViewID={inputAccessoryViewID}/>
        </View>

    );
}

export default BuscarGastos;