import React, { Fragment } from 'react'
import { Text } from 'react-native-paper'
import { TouchableOpacity, View, ScrollView, SafeAreaView, Dimensions, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Comprobante/arrow.svg'
import ResumenRendicion from '../../components/ResumenRendicion'
import {useNavigation} from '@react-navigation/native'
import CameraComponent from '../../components/camera'
import HeaderCard from '../../components/header-tarjeta'

import SvgFormatter from '../../../svgFormatter';

const AdjuntanteTicket = ( rendicionHeredada ) => {
        
    const navigation = useNavigation();
    var rendicion = rendicionHeredada.route.params;

     let styleHeight = Dimensions.get('window').height;
     return (
        <Fragment>
            <SafeAreaView style={{backgroundColor:'#075330'}} />
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <ScrollView>
                    <View style={[styles.container, {minHeight: styleHeight}]}>
                        <View style={styles.card}>
                            <View style={{flexDirection:'row'}}>
                            <TouchableOpacity onPress={()=>navigation.pop()}>
                                 { Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center' }}/> : <ArrowSVG/> }
                            </TouchableOpacity>
                            {Platform.OS !== 'web' ? <HeaderCard style={{height:30}} whiteColor={false}/> : <></>}
                            </View>
                          
                            <View style={{flexDirection:'row'}}>
                                <View>
                                    <Text style={styles.title}>Adjuntar Ticket</Text>
                                    <Text style={styles.categories}>Generar Rendición</Text>
                                </View>
                                {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                                    <HeaderCard />
                                </View> : <></> }
                            </View>
                            {rendicion.esNueva  ?
                                <></>
                                :
                                <ResumenRendicion datosRendicion={rendicion} /> 
                            }                            
                            <CameraComponent datosRendicion={rendicion} />
                        </View>       
                    </View>
                </ScrollView>
            </SafeAreaView>
        </Fragment>
      );
}


export default AdjuntanteTicket;