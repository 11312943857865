import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        color: '#353D4A',
        fontSize: 30,
        marginBottom: 16,
        marginTop: 20,
        fontFamily: 'Gotham-Bold'
    },
    cardRendicion:{
        marginVertical: 10,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,  
        elevation: 3
    },
    textCardRendicion:{
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    textCardRendicion2:{
        marginTop: 3,
        fontSize: 14,
        fontFamily: 'Gotham'
    },
    horizontalLine: {
        backgroundColor: '#B1B5BE4D',
        height: 2,
        marginBottom: 10
    },
    icons: {
        marginRight: 10
    },
    comprobante: {
        marginTop: 8,
        marginBottom: 4,
        padding: 30,
        borderRadius: 10
    },
    comprobanteItem: {
        marginTop: 8,
        marginBottom: 4,
        borderRadius: 10
    },
    comprobanteTextTarjeta:{
        marginTop: 3,
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'Gotham'
    },
    comprobanteTextGasto:{
        marginTop: 3,
        fontSize: 16,
        fontFamily: 'Gotham-Bold'
    },
    comprobanteTextSubtitulo:{
        marginTop: 3,
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    comprobanteTextTitulo:{
        marginTop: 3,
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    btnSiguiente: {
        padding: 12,
        marginTop: 10, 
        borderRadius: 10,
        backgroundColor: '#0A834B', 
        marginBottom: 30,
      },
      textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    categories:{
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12,
        fontFamily: 'Gotham-Bold'
    },
    textInput: {
        padding: 7,
        borderColor: '#B1B5BE4D',
        borderWidth: 1,
        fontWeight: '500',
        height: 100,
        textAlignVertical: 'top'
    },
    inputSection: {
        marginTop: 1,
        marginBottom: 40
    }
});

export default styles;