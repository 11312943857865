import * as React from 'react';
import { Platform, InputAccessoryView, TouchableOpacity, Keyboard } from 'react-native';
import { Text } from 'react-native-paper';

export default (props) => {
    if (Platform.OS == "ios")
        return (<InputAccessoryView nativeID={props.inputAccessoryViewID} style={{borderWidth:1}} backgroundColor='#f4f4f4'>
        <TouchableOpacity style={{margin:7}}
          onPress={Keyboard.dismiss}
          >
          <Text style={{textAlign: 'right',marginRight: 30, color:'blue', fontWeight: 'bold'}}>Listo</Text>
        </TouchableOpacity>
      </InputAccessoryView>)
    else
        return <></>    
}