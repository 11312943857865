import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#008B47',
        alignItems: 'center',
        justifyContent: 'center',
    },
    titulo: {
        fontSize: 34,
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        fontFamily: 'Gotham'

    },
    titulo2: {
        fontSize: 14,
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 50,
        fontFamily: 'Gotham-Bold'

    },
    input: {
        height: 40,
        width: 250,
        backgroundColor: '#008B46',
        margin: 10,
        padding: 10,
        marginTop: 10,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: 'white',
        fontFamily: 'Gotham-Bold',
        fontSize: 15,
        color: 'white'

    },
    btnSecion: {
        padding: 10,
        paddingHorizontal: 80,
        backgroundColor: '#fff',
        marginTop: 40,
        borderRadius: 10,
    },
    textoEliminar: {
        color: '#008B47',
        fontWeight: 'bold',
        textAlign: 'center',

    },
});

export default styles;
