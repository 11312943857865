import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
      
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contenedor: {
        flexDirection:"row",
        alignItems: "center",
        justifyContent: "center",
      },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    creditCard: {        
        marginRight: 100,
    },
    title: {
        color: '#353D4A',
        fontSize: 25,
        marginBottom: 16,
        marginTop: 20,
        fontFamily: 'Gotham-Bold'
    },
    categories:{
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12,
        fontFamily: 'Gotham-Bold'
    },
    cardRendicion: {
        //height: 50,
        maxWidth:1500,
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,        
    },
    textCardRendicion:{
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    textCardRendicion2:{
        marginTop: 3,
        fontSize: 14,     
        fontFamily: 'Gotham'
    },
    horizontalLine: {
        backgroundColor: '#B1B5BE4D',
        height: 2,
        marginBottom: 10
    },
    fraction:{
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 50,
        elevation: 2,                
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        shadowOpacity: 0.8,
        shadowRadius: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: {
        height: 1,
        width: 1,
        },
        width: "100%",
    },
    icons: {
        marginRight: 10
    },
    textFraction: {
        flexWrap: 'nowrap',
    },
    viewBuscar:{
        marginVertical: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textBuscar:{
        marginTop: 8,
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    greenText:{
        marginTop: 8,
        fontFamily: 'Gotham'
    },
    btnTipoRendicion: {
        width: "100%",
        height: 100,
        padding: 12,
        backgroundColor: '#ffffff',
        marginTop: 160,            
        marginBottom: 30,        
        borderRadius: 20,
        shadowOpacity: 0.8,
        shadowRadius: 2,
        shadowOffset: {
        height: 1,
        width: 1,
        }

    },
        textTipoRendicion: {
        color: '#0a834b',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 22,
        textAlignVertical: 'center',
    },
    textWarning: {
        color: '#C72141',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    titulo: {
        color: '#353D4A',
        fontWeight: '500',
        fontFamily: 'Gotham'

    },
    subcontainer: {
        flexDirection: "row",

        marginTop: 20,
        alignContent: 'flex-start',
    },
    textNombre: {
        fontSize: 27,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        fontWeight: 'bold',
        fontFamily: 'Gotham'

    },
    textNumero: {
        fontSize: 14,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        fontWeight: '500'
    }
});

export default styles;
