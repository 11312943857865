import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        color: '#353D4A',
        fontSize: 28,
        marginBottom: 16,
        marginTop: 20,
        fontFamily: 'Gotham-Bold'
    },
    categories:{
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12,
        fontFamily: 'Gotham-Bold'
    },
    cardVehiculo: {
        //height: 50,
        maxWidth:1500,
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        borderRadius: 20,        
    },
    textCardRendicion:{
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    textCardRendicion2:{
        marginTop: 3,
        fontSize: 14,     
        fontFamily: 'Gotham'
    },
    textVehiculo: {
        color: '#0a834b',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 14,
        textAlignVertical: 'center',
    },
    textDominio: {
        color: '#7e830a',        
        textAlign: 'center',
        fontSize: 12,
        textAlignVertical: 'center',
    },
    textSinVehiculo: {
        paddingVertical: 40,        
        fontSize: 30,
        color: '#d3d3d3',
        alignContent: 'center',        
        textAlign: 'center'
    },
    horizontalLine: {
        backgroundColor: '#B1B5BE4D',
        height: 2,
        marginBottom: 10
    },
    fraction:{
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: 50,
        elevation: 2,                
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        shadowOpacity: 0.8,
        shadowRadius: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: {
        height: 1,
        width: 1,
        },
        width: "100%",
    },
    icons: {
        marginRight: 0
    },
    textFraction: {
        flexWrap: 'nowrap',
    },
    greenText:{
        marginTop: 8,
        fontFamily: 'Gotham'
    },
    btnSiguiente: {
        padding: 12,
        backgroundColor: '#0A834B',
        marginTop: 10, 
        borderRadius: 10,
        marginBottom: 30
    },
        textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    textWarning: {
        color: '#C72141',
        fontWeight: 'bold',
        textAlign: 'center',
    }
});

export default styles;
