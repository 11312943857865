import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        color: '#353D4A',
        fontSize: 30,
        marginBottom: 16,
        marginTop: 20,
        fontFamily: 'Gotham-Bold'
    },
    categories:{
        fontFamily: 'Gotham-Bold',
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12
    },
    cardRendicionWeb:{
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    cardRendicion: {
        marginVertical: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    textCardRendicion:{
        fontSize: 14,
        fontFamily: 'Gotham'
    },
    textCardRendicion2:{
        marginTop: 3,
        fontSize: 14,
        fontFamily: 'Gotham'
    },
    icons: {
        marginRight: 10
    },
    textFraction: {
        flexWrap: 'nowrap',
    },
    categories:{
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12,
        fontFamily: 'Gotham-Bold'
    },
    textInput: {
        padding: 7,
        borderColor: '#B1B5BE4D',
        borderWidth: 1,
        fontWeight: '500',
        height: 100,
        textAlignVertical: 'top'
    },
    warning: {
        fontSize: 14,
        color: '#FF0000',
        alignSelf: 'center',
        justifyContent: 'center',
        marginTop: "3%",
        fontFamily: 'Gotham-Bold'
    },
    inputSection: {
        marginTop: 1,
        marginBottom: 40
    },
    btnSiguiente: {
        padding: 12,
        marginTop: "3%", 
        borderRadius: 10,
        backgroundColor: '#0A834B', 
        marginBottom: 30,
        width: (Platform.OS === 'web') ? '20%' : '100%'
    },
      textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
      btnEliminar: {
        backgroundColor: 'red',
        marginTop: 10,
        padding: 10,
      },
    textoEliminar: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        textTransform: 'uppercase',
        width: 300
    },
    textImporte: {        
        height: 40,
        width: 200,
        borderColor: 'gray',
        borderWidth: 1,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center'
    },
    textFecha: {        
        borderColor: 'gray',        
        fontWeight: 'bold',        
    },
    labelFecha: {
        borderRadius: 20, 
        backgroundColor:'#008B47',        
        color: '#ffff',
        paddingLeft: 20,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 20
    },
    textWarning: {
        color: '#C72141',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    greenPlusIcon:
    {        
        marginTop: '2%',
        marginBottom: '2%',
    },
    horizontalLineUp: {
        backgroundColor: '#B1B5BE4D',
        height: 2,        
        marginTop: 40
    },
    horizontalLineDown: {
        backgroundColor: '#B1B5BE4D',
        height: 2,
        marginBottom: 10        
    },
    textSinGastos: {
        paddingVertical: 40,        
        fontSize: 30,
        color: '#d3d3d3',
        alignContent: 'center',        
        textAlign: 'center'
    }
});
export default styles;