import 'react-native-gesture-handler'
import React from 'react'
import { View, Text, Platform } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import Login from './src/containers/Login'
import TipoRendicion from './src/containers/TipoRendicion'
import Tarjetas from './src/containers/Tarjetas'
import BuscarGastos from './src/containers/BuscarGastos'
import MisGastos from './src/containers/misGastos'
import MenuLateral from './src/containers/MenuLateral'
import Comprobante from './src/containers/Comprobante'
import DetalleRendiciones from './src/containers/DetalleRendiciones'
import AdjuntanteTicket from './src/containers/AdjuntanteTicket'
import Comentario from './src/containers/Comentario'
import Estado from './src/containers/Estado'
import Categoria from './src/containers/Categoria'
import GestionDesglose from './src/containers/GestionDesglose'
import CentroCosto from './src/containers/CentroCosto'
import Toast from 'react-native-toast-message'
import { AuthContext } from './src/containers/app/context'
import CardContextProvider from './src/context/CardContext'
import RendicionContextProvider from './src/context/RendicionContext'
import LegajoContextProvider from './src/context/LegajoContext'
import OtrasRendiciones from './src/containers/OtrasRendiciones'
import NuevaRendicion from './src/containers/NuevaRendicion'
import Vehiculo from './src/containers/Vehiculo'

//import { AsyncStorage } from 'react-native';
import * as Font from 'expo-font';

import AsyncStorage from '@react-native-async-storage/async-storage';


const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

Font.loadAsync({    
    'Gotham': require('./assets/fonts/Gotham/gotham.otf'),
    'Gotham-Bold': require('./assets/fonts/Gotham/gotham-bold.otf')
});

function CustomDrawerContent(props) {

    const handleDrawerBack = () => { }
    return (
        <DrawerContentScrollView {...props}>
            <MenuLateral handleDrawerBack={handleDrawerBack()} />
        </DrawerContentScrollView>
    );
}

function DrawerNavigator({ navigation }) {
    return (
        <Drawer.Navigator
            drawerType='slide'
            overlayColor='transparent'
            nitialRouteName="Home"
            drawerContent={CustomDrawerContent}
            options={{
                headerShown: false,
            }}
        >
            <Drawer.Screen name="MisGastos" component={MisGastos}
                options={{
                    headerShown: false,
                }}
            />
            <Drawer.Screen name="MenuLateral" component={MenuLateral} />
        </Drawer.Navigator>
    );
}

function DrawerNavigatorOtros({ navigation }) {
    return (
        <Drawer.Navigator
            drawerType='slide'
            overlayColor='transparent'
            nitialRouteName="Home"
            drawerContent={CustomDrawerContent}
            options={{
                headerShown: false,
            }}
        >
            <Drawer.Screen name="MisGastos" component={OtrasRendiciones}
                options={{
                    headerShown: false,
                }}
            />
            <Drawer.Screen name="MenuLateral" component={MenuLateral} />
        </Drawer.Navigator>
    );
}

const toastConfig = {
    'exito': (internalState) => (
        <View style={{ height: 60, width: '100%', backgroundColor: 'pink' }}>
            <Text>{internalState.text1} agua</Text>
        </View>
    ),
    'error': () => { },
    'info': () => { },
    'any_custom_type': () => { }
}

const AppNavigator = () => {

    initialLoginState = {
        isLoading: true,
        userName: null,
        userToken: null
    }
    const loginReducer = (prevState, action) => {
        switch (action.type) {
            case 'LOGIN':
                return {
                    ...prevState,
                    userName: action.id,
                    userToken: action.token,
                    isLoading: false
                };
            case 'RETRIEVE_TOKEN':
                return {
                    ...prevState,
                    userToken: action.token,
                    isLoading: false
                };
            case 'SIGN_OUT':
                return {
                    ...prevState,
                    isSignout: true,
                    userToken: null,
                };
        }
    }
    const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);

    const authContext = React.useMemo(() => ({
        signIn: async (userName, newUserToken) => {
            dispatch({ type: 'LOGIN', id: userName, token: newUserToken })
            try {
                await AsyncStorage.setItem('@storage_Key', newUserToken)
            } catch (e) {
                console.log(e)
            }
        },
        signOut: () => dispatch({ type: 'SIGN_OUT' }),

    }), [])

    React.useEffect(() => {
        setTimeout(async () => {
            let userToken = null;
            try {
                userToken = await AsyncStorage.getItem('@storage_Key')
                /*         if(token !== null) {
                          // value previously stored
                        } */

            } catch (e) {
                console.log(e)
            }



            dispatch({ type: 'RETRIEVE_TOKEN', token: userToken })
        }, 1000)
    }, [])

    return (
        <AuthContext.Provider value={authContext}>
            <CardContextProvider>
            <RendicionContextProvider>
            <LegajoContextProvider>
            <NavigationContainer>
                {loginState.isLoading ?
                    <Loading />
                    :
                    loginState.userToken ?
                        <Stack.Navigator>                            
                            <Stack.Screen name="Tarjetas" component={Tarjetas} options={{headerShown: false}} />
                            <Stack.Screen name="TipoRendicion" component={TipoRendicion} options={{headerShown: false}} />
                            <Stack.Screen name="MisGastos" component={DrawerNavigator} options={{ headerShown: false }} />
                            <Stack.Screen name="Buscar gastos" component={BuscarGastos} options={{ headerShown: false }} />
                            <Stack.Screen name="Comentario" component={Comentario} options={{ headerShown: false }} />
                            <Stack.Screen name="Comprobante" component={Comprobante} options={{ headerShown: false }} />
                            <Stack.Screen name="DetalleRendiciones" component={DetalleRendiciones} options={{ headerShown: false }} />
                            <Stack.Screen name="Estado" component={Estado} options={{ headerShown: false }} />
                            <Stack.Screen name="Categoria" component={Categoria} options={{ headerShown: false }} />
                            <Stack.Screen name="GestionDesglose" component={GestionDesglose} options={{ headerShown: false }} />
                            <Stack.Screen name="CentroCosto" component={CentroCosto} options={{ headerShown: false }} />                            
                            <Stack.Screen name="AdjuntanteTicket" component={AdjuntanteTicket} options={{ headerShown: false }} />
                            <Stack.Screen name="BuscarGastos" component={BuscarGastos} options={{ headerShown: false }} />
                            <Stack.Screen name="OtrasRendiciones" component={DrawerNavigatorOtros} options={{ headerShown: false }} />
                            <Stack.Screen name="NuevaRendicion" component={NuevaRendicion} options={{ headerShown: false }} />
                            <Stack.Screen name="Vehiculo" component={Vehiculo} options={{ headerShown: false }} />
                        </Stack.Navigator>
                        :
                        <Stack.Navigator>
                            <Stack.Screen name="Login" component={Login} options={{ headerShown: false }} />
                        </Stack.Navigator>

                }
            </NavigationContainer>
            </LegajoContextProvider>
            </RendicionContextProvider>
            </CardContextProvider>
        </AuthContext.Provider>

    )
}

const Loading = () => (
    <>
        <Text>cargando app...</Text>
    </>
)

const App = () => (
    <>
        <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
        <AppNavigator />
    </>
);

export default App;
