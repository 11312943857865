
import React, { useContext, useCallback, useEffect, useState } from 'react'
import { Text } from 'react-native-paper'
import styles from './styles'
import { TouchableOpacity, View, SafeAreaView, Dimensions, Platform } from 'react-native'
import CreditCardSVG from '../../resources/menu Lateral/CreditCard.svg'
import ProfilePicSVG from '../../resources/menu Lateral/ProfilePic.svg'
//import { AsyncStorage } from 'react-native'
import VersionCheck from 'react-native-version-check-expo'
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import { AuthContext } from '../app/context';
import { CardContext } from '../../context/CardContext';
import { useNavigation } from '@react-navigation/native';

import AsyncStorage from '@react-native-async-storage/async-storage';

const MenuLateral = () => {
    const navigation = useNavigation();
    const { signOut } = React.useContext(AuthContext);
    const {card} = useContext(CardContext);

    const [datos, setDatos] = useState([]);
    let styleHeight = Dimensions.get('window').height;
    let version = VersionCheck.getCurrentVersion();

    useEffect(() => {
        async function fetchData() {
            const userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(API_URL + '/Tarjetas', {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            const data = await response.json();
            const [item] = data;
            setDatos(item);
        }
        fetchData();
    }, []);

    const handleSignout = () => {
        if (Platform.OS == "web"){
            AsyncStorage.removeItem("@storage_Key").then(() => {
                window.location.reload();
            });
        }else{
            AsyncStorage.removeItem("@storage_Key").then(() => {
                signOut();
            });
        }
    }             

    return (
        <SafeAreaView>
            <View style={[styles.container, { minHeight: styleHeight }]}>
                <View>
                    { Platform.OS == "web" ? <img height="50%" width="50%"  src={ProfilePicSVG} /> : <ProfilePicSVG/> }
                    <View>
                        <Text style={styles.textNombre}>{card.nombre}</Text>
                    </View>
                    <View style={styles.horizontalLine} />
                </View>                
                <View>
                    <TouchableOpacity style={styles.cambiarPantalla} onPress={() => 
                        navigation.navigate('TipoRendicion')
                    }>
                        <Text style={styles.cerrarSesionText}>Cambiar tipo de rendición</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cambiarPantalla} onPress={() => 
                        navigation.navigate('Tarjetas')
                    }>
                        <Text style={styles.cerrarSesionText}>Cambiar de legajo</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.cerrarSesion} onPress={() => {
                        handleSignout();
                    }}>
                        <Text style={styles.cerrarSesionText}>Cerrar sesión</Text>
                    </TouchableOpacity>
                </View>
                <Text style={styles.textNumero}>Versión {version}</Text>
            </View>
        </SafeAreaView>
    );
}

export default MenuLateral;
