import React,{useCallback} from 'react'
import { View, Dimensions,TouchableOpacity, Text, Platform } from 'react-native'
import { TabView, TabBar } from 'react-native-tab-view'
import styles from './styles'
import ResumenOtraRendicion from '../ResumenOtraRendicion'
import { useNavigation } from '@react-navigation/native'
import { SwipeListView } from 'react-native-swipe-list-view';

import SvgFormatter from '../../../svgFormatter';

const TabTodas = props => {

    const navigation = useNavigation();
    const refresh = props.refreshList;
    return (
        <View style={styles.scene}>
            {props.rendiciones.map(rendicion => (
                    <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
                        <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
                    </TouchableOpacity>
            ))}
        </View>
    );
}
const TabPendientes = props => {

  const navigation = useNavigation();
  const refresh = props.refreshList;
  return (
    <View style={styles.scene}>
        {props.rendiciones.map( rendicion =>(
           rendicion.estado == 'Pendiente' &&
              <TouchableOpacity 
                  onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} 
                  key={rendicion.id} >
                  <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
              </TouchableOpacity>
          ))
      }
      {props.rendiciones.map( rendicion =>(
           rendicion.estado == 'Anulada' &&
              <TouchableOpacity 
                  onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} 
                  key={rendicion.id} >
                  <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
              </TouchableOpacity>
          ))
      }
    </View>
);}
const TabRendidas  = props => { 

  const navigation = useNavigation();
  const refresh = props.refreshList;
  return( 
    <View style={styles.scene}>
      {props.rendiciones.map( rendicion =>(
         rendicion.estado != "Enviada" && rendicion.estado != null && rendicion.estado != 'Anulada' && rendicion.estado != 'Pendiente' && rendicion.estado != 'Desconocido' && 
          <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
            <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
        </TouchableOpacity>
        ))
      }
    </View>
);}
const TabEnviadas  = props => { 

  const navigation = useNavigation();
  const refresh = props.refreshList;
  return( 
    <View style={styles.scene}>
      {props.rendiciones.map( rendicion =>(
         rendicion.estado == 'Enviada' && 
          <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
            <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
        </TouchableOpacity>
        ))
      }
    </View>
);}
const TabDesconocidas  = props => 
{ 

  const navigation = useNavigation();
  const refresh = props.refreshList;
  return( 
    <View style={styles.scene}>
      {props.rendiciones.map( rendicion =>(
         rendicion.estado == 'Desconocido' &&
          <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
           <ResumenOtraRendicion refreshList={refresh} datosRendicion={rendicion} />
        </TouchableOpacity>
        ))
      }
    </View>
);}

 
const initialLayout = { width: Dimensions.get('window').width };
 
 const  TabEstados= ( props ) => {

  const [index, setIndex] = React.useState(0);

  const [routes] = React.useState([
    { key: 'Todas', title: 'Todas' },
    { key: 'Pendientes', title: 'Pendientes' },
    { key: 'Enviadas', title: 'Enviadas'},
    { key: 'Rendidas', title: 'Rendidas'},
    { key: 'Desconocidas', title: 'Desconocidas'}    

  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Todas':
        return <TabTodas refreshList={props.refreshList} handleMisGastosPage={props.handleMisGastosPageNavigate} rendiciones={props.rendiciones} />;
      case 'Pendientes':
        return <TabPendientes refreshList={props.refreshList} handleComprobanteNav={props.handleDetalleRendicionesNav} rendiciones={props.rendiciones} />;
      case 'Rendidas':
        return <TabRendidas refreshList={props.refreshList} rendiciones={props.rendiciones} />
      case 'Enviadas':
        return <TabEnviadas  refreshList={props.refreshList} rendiciones={props.rendiciones} />      
        case 'Desconocidas':
        return <TabDesconocidas  refreshList={props.refreshList} rendiciones={props.rendiciones} />      
      default:
        return null;
    }
  };

  const renderTabBar = props => (
    
    <TabBar
      {...props}
      indicatorStyle={styles.tabBarText}
      style={styles.tabBarBackground}
      tabStyle={{ width: 120 }}
      labelStyle={styles.posibleStatus}
      inactiveColor= '#B1B5BE'
      activeColor=  '#008B47'
      scrollEnabled={true}
      getLabelText={({ route }) => route.title}
      tabStyle={{ width: 'auto' }}
    />
  );
  
  const navigation = useNavigation();
    
  return (
    <View>          
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={initialLayout}
        style={styles.base}
        renderTabBar={renderTabBar}
      />
    </View>
  );
}
 


export default TabEstados