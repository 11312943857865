import { StyleSheet } from 'react-native'

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        color: '#353D4A',
        fontSize: 28,
        marginBottom: 16,
        marginTop: 20,
        fontFamily: 'Gotham-Bold'
    },
    categories:{
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12,
        fontFamily: 'Gotham-Bold'
    },
    cardRendicion:{
        marginVertical: 10,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,  
        elevation: 3
    },
    textCardRendicion:{
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    textCardRendicion2:{
        marginTop: 3,
        fontSize: 14,     
        fontFamily: 'Gotham'
    },
    horizontalLine: {
        backgroundColor: '#B1B5BE4D',
        height: 2,
        marginBottom: 10
    },
    fraction:{
        marginVertical: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    icons: {
        marginRight: 10
    },
    textFraction: {
        flexWrap: 'nowrap',
    },
    viewBuscar:{
        marginVertical: 4,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textBuscar:{
        marginTop: 8,
        fontSize: 16,
        fontFamily: 'Gotham'
    },
    greenText:{
        marginTop: 8,
        fontFamily: 'Gotham'
    },
    btnSiguiente: {
        padding: 12,
        backgroundColor: '#0A834B',
        marginTop: 10, 
        borderRadius: 10,
        marginBottom: 30
      },
      textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    textImporte: {
        height: 40,
        width: 200,
        borderColor: 'gray',
        borderWidth: 1,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center'
    },
    textWarning: {
        color: '#C72141',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    containerImporte: {
        flexDirection:"row",
        alignSelf:"center",
        alignItems:"center",
        marginTop: 20
    },
    containerFecha: {
        flexDirection:'column',
        marginLeft: '2%'
    },
    containerFechaMobile: {
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: 20
    },
    labelFecha: {
        height: 40,
        width: 200,
        borderColor: 'gray',
        borderWidth: 1,
        fontWeight: 'bold',
        alignSelf: 'center',
        textAlign: 'center',
        textAlignVertical: 'center'
    },
});

export default styles;
