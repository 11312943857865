import React, { useCallback, useState, Fragment } from 'react';
import { Text, TextInput, Divider } from 'react-native-paper';
import { TouchableOpacity, View, FlatList, Dimensions, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Categoria/arrow.svg'
import GreenCloseSVG from '../../resources/GestionDesglose/green-close.svg'
import GreenPlusSVG from '../../resources/GestionDesglose/green-plus.svg'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import KeyboardDone from '../../../keyboardDone';
import SvgFormatter from '../../../svgFormatter';
import HeaderCard from '../../components/header-tarjeta';
import DateTimePicker from '@react-native-community/datetimepicker';

const NuevaRendicion = (status) => {
    const inputAccessoryViewID = 'done';
    const rendicion = status.route.params;    
    let styleHeight = Dimensions.get('window').height
    const navigation = useNavigation();
    const [importe, setImporte] = useState(0);
    const [textwarning, setTextwarning] = useState("");    
    const [date, setDate] = useState(new Date());
    const [mode, setMode] = useState('date');
    const [show, setShow] = useState(false);        
    
    rendicion.fecha = AddZerosToDate(date);
    
    const [desglose, setDesglose] = useState([])

    const handleVolver = useCallback(() => {
        navigation.pop();
    })    

    navigation.addListener('focus', () => {
        setDesglose(rendicion.DATA.desgloseGasto);
        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
           if(Number(desgloseGasto.importe) == 0){
                removeItem(desgloseGasto.key);
           }
        }
    });

    function DateTimePickerWeb(value, id) {                
        return React.createElement('input', {
          type: 'date',
          defaultValue:  AddZerosToDate(value),      
          id: id,
          onChange: handleChange,
          style: {fontFamily: "Gotham", marginLeft: 15},
          required: "required"
        })
    }

    const ValidarSiguiente = () => {        
        var SumImporte = 0;

        if(desglose.length == 0)
            return false;

        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
            SumImporte = SumImporte + Number(desgloseGasto.importe);
        }
        
        return (rendicion.importe == SumImporte);
    }

    const renderDesgloseWeb = (item) => (
        <View style={styles.cardRendicionWeb} key={item.key}>
            <Text style={[styles.textCardRendicion]}>Categoria: {item.categoriaDes}</Text>
            <Text style={[styles.textCardRendicion]}>Centro decosto: {item.centroCostoDes}</Text>
            {(rendicion.gastoEnCantidad) ? <Text style={[styles.textCardRendicion]}>Cantidad: {item.importe}</Text> : <Text style={[styles.textCardRendicion]}>Importe: {rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text>}            
            {(rendicion.gastoEnCantidad) ? <Text style={[styles.textCardRendicion]}>Fecha de desglose: {item.FechaDesglose}</Text> : null}            
            <TouchableOpacity onPress={() => removeItem(item.key)}>
                <img src={GreenCloseSVG}/>
            </TouchableOpacity>
        </View>
    );

    const renderDesglose = (item) => (
        <View style={styles.cardRendicion} key={item.key}>
            <Text style={[styles.textCardRendicion]}>{item.categoriaDes}</Text>
            <Text style={[styles.textCardRendicion]}>{item.centroCostoDes}</Text>
            {(rendicion.gastoEnCantidad) ? <Text style={[styles.textCardRendicion]}>{item.importe}</Text> : <Text style={[styles.textCardRendicion]}>{rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text>}            
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => removeItem(item.key)}>
                <GreenCloseSVG />
            </TouchableOpacity>
        </View>
    );

    const removeItem = (key) => {         
        const newList = rendicion.DATA.desgloseGasto.filter((r) => r.key !== key);
        rendicion.DATA.desgloseGasto = newList;
        setDesglose(newList);        
    }

    const onChange = (event, selectedDate) => {          
        const currentDate = selectedDate || date;        
        setShow(Platform.OS === 'ios');
        setDate(currentDate);
    };

    const handleFecha = async () => {
        setShow(true);
    }

    const handleImporte =  (text) => {        
        setImporte(text.replace(/[^0-9]/g, ''));
        rendicion.importe = text.replace(/[^0-9]/g, '');        
    }
    
    const handleNuevoDesglose = () => {        
        navigation.navigate('Categoria', rendicion);
    }

    function handleChange(e){  
        var fechaActual = new Date(); 
        var timestamp = Date.parse(e.currentTarget.value);
        var dateObject = new Date(timestamp);
        if(dateObject > fechaActual){
            document.getElementById(e.currentTarget.id).value = AddZerosToDate(fechaActual);
        }
        
        const [year, month, date] = e.currentTarget.value.split("-");
        const dateString = date + "-" + month + "-" + year;
        const dateSeleccionada = new Date( dateString.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") );
        
        setDate(dateSeleccionada);
        rendicion.fecha = AddZerosToDate(dateSeleccionada);
    }    

    function AddZerosToDate(date) {          
        var dateString;                
        dateString = date.getFullYear()
                    + '-' + ('0' + (date.getMonth()+1)).slice(-2)             
                    + "-" + ('0' + date.getDate()).slice(-2);             
        
        return dateString;
    }    

    return (
        <Fragment>
            <SafeAreaView style={{ backgroundColor: '#075330' }}>
                <FlatList ListFooterComponent={
                    <View style={[styles.container, { minHeight: styleHeight }]}>
                        <View style={styles.card}>                                                        
                            <View style={{flexDirection:'row'}}>
                                <TouchableOpacity onPress={handleVolver}>
                                    {Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center' }} /> : <ArrowSVG />}
                                </TouchableOpacity>
                                    {Platform.OS !== 'web' ? <HeaderCard style={{height:30}}/> : <></>}
                            </View>
                            
                            <View style={{flexDirection:'row'}}>
                                <View>
                                    <Text style={styles.title}>Nuevo Gasto</Text>
                                    <Text style={styles.categories}>Generar Rendición</Text>
                                </View>
                               {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                                    <HeaderCard />
                                </View> : <></>}
                            </View> 
                            
                            { Platform.OS == 'web' ?
                                <View style={{flexDirection:'row', marginTop: '2%', alignItems: 'center'}}>
                                    <Text style={styles.textFecha}>Fecha del gasto: </Text>
                                    <View style={{width: '13%'}}>
                                        {DateTimePickerWeb(new Date(),"fechaGasto")}
                                    </View>                                    
                                </View>
                            :
                            <View style={{alignItems: 'center', alignContent: 'center', alignSelf: 'center'}}>
                                <View style={{flexDirection:'row', marginTop: '2%', alignItems: 'center'}}>
                                    <Text style={styles.textFecha}>Fecha del gasto</Text>                                
                                </View>
                                <View style={{flexDirection:'row', marginTop: '2%', alignItems: 'center'}}>
                                    <TouchableOpacity onPress={handleFecha}>
                                        <Text style={styles.labelFecha}>{ date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</Text>
                                    </TouchableOpacity>
                                    {show && (                                        
                                        <DateTimePicker
                                        testID="dateTimePicker"
                                        value={date}
                                        maximumDate={new Date()}
                                        mode={mode}
                                        is24Hour={true}
                                        display="default"
                                        onChange={onChange}                                        
                                        />
                                    )}
                                </View>
                            </View>
                            }
                                                        
                            <Divider style={styles.horizontalLineUp} />
                            <View style={styles.greenPlusIcon}>                                
                                <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => handleNuevoDesglose()}>
                                    {Platform.OS == "web" ?
                                        <img src={GreenPlusSVG} style={{ width: '80px' }} />
                                        : <SvgFormatter SvgPath={GreenPlusSVG} />} 
                                </TouchableOpacity>                                                                
                            </View>                                                       
                            <View>
                                {desglose.length == 0 ?
                                    <View style={{alignSelf: 'center'}}>
                                        <Text style={styles.textSinGastos}>Aún no existen desgloses para este gasto.</Text>
                                    </View>
                                    :
                                    <></>
                                }
                                {Platform.OS == "web" ?
                                    <View>{desglose.map(r => renderDesgloseWeb(r))}</View> :
                                    <View>{desglose.map(r => renderDesglose(r))}</View>}                                
                            </View>
                            <Divider style={styles.horizontalLineDown} />                            
                            <KeyboardDone inputAccessoryViewID={inputAccessoryViewID} />
                            <View style={{ alignSelf: 'center' }}>
                                {(rendicion.gastoEnCantidad) ? <Text style={{ fontWeight: 'bold', marginTop: 40 }}>Cantidad: </Text> : <Text style={{ fontWeight: 'bold', marginTop: 40 }}>Importe: </Text>}        
                            </View>
                            <View>
                                <TextInput style={styles.textImporte}
                                    onChangeText={text => handleImporte(text.replace(/[^0-9]/g, ''))}
                                        value={importe.toString()}
                                    />
                                <Text style={styles.textWarning}>{textwarning}</Text> 
                            </View>
                            <View style={{alignItems: 'center'}}>
                                {ValidarSiguiente()==true ? 
                                    <TouchableOpacity style={styles.btnSiguiente} onPress={() => navigation.navigate('AdjuntanteTicket', rendicion)}>
                                        <Text style={styles.textSiguiente}>Siguiente</Text>
                                    </TouchableOpacity>
                                    :
                                    (rendicion.gastoEnCantidad) ? <Text style={styles.warning}>La suma de los Desgloses debe ser igual a la cantidad total ingresada.</Text>  : <Text style={styles.warning}>La suma de los Desgloses debe ser igual al importe total ingresado.</Text>   
                                }                                
                            </View>
                        </View>                        
                    </View>
                } />
            </SafeAreaView>
        </Fragment>
    );
}
export default NuevaRendicion;