
import React, { useCallback, useState, useEffect,Fragment, useContext} from 'react';
import { Text, TextInput, Divider} from 'react-native-paper';
import ResumenRendicion from '../../components/ResumenRendicion';
import { TouchableOpacity, View, FlatList, ActivityIndicator, Dimensions, Platform, Alert } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Categoria/arrow.svg'
import ListSVG from '../../resources/Categoria/list.svg'
import EllipseSVG from '../../resources/Categoria/ellipse.svg'
import EllipseCheckSVG from '../../resources/Categoria/ellipseChek.svg'
import { useNavigation } from '@react-navigation/native'
import { Searchbar } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context';
import { API_URL } from '../../config/constants';
import KeyboardDone from '../../../keyboardDone';
import HeaderCard from '../../components/header-tarjeta';
import { LegajoContext } from '../../context/LegajoContext';

import SvgFormatter from '../../../svgFormatter';

import AsyncStorage from '@react-native-async-storage/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';


const CentroCosto = ( status ) => {
    const {legajo} = useContext(LegajoContext);
    const inputAccessoryViewID = 'done';
    let styleHeight = Dimensions.get('window').height
    const navigation = useNavigation();
    const rendicion = status.route.params;
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);
    rendicion.estado = rendicion.estadoAnterior;
    const [textwarning, setTextwarning] = useState("");
    const [importe, setImporte] = useState(0);
    const [date, setDate] = useState(new Date());    
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState('date');  


    const handleVolver = useCallback(() => {
        navigation.pop();
    })

    const SelectItem =  (prop) =>{
        setSelectedId(prop.idCentroCosto)
        rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].centroCostoId = prop.idCentroCosto;
        rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].centroCostoDes = prop.descripcion;
    }
     //Llamada a la API
    const fetchData = async () => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var response;
        var data = [];
        
        if(rendicion.esNueva){
            response = await fetch(API_URL + '/Gastos/GetCentrosCostoLegajo/' + legajo, {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });

            data[0] = await response.json();
        }
        else{
            response = await fetch(API_URL + '/Gastos/GetCentrosCostoDefault/' + rendicion.id, {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });

            data = await response.json();
        }        
                
        setDatos(data);
        setSearchQuery(data);
        setLoading(false);

        var SumImporte = 0;
        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
            SumImporte = SumImporte + Number(desgloseGasto.importe);
        };
        
        if(!rendicion.esNueva)
        {
            setImporte(rendicion.importe - SumImporte);
        }        
    }
    useEffect(() => {
        fetchData()
    },[])
    
    //Searcher
    const searchFilterFunction = async text => {
        if (text.length > 2) {
            const userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(API_URL + '/Gastos/GetCentrosCosto/' + text, {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            const data = await response.json();
            if (data.length == 0) {
                setSearchQuery(datos);
            } else {
                setSearchQuery([]);
                setSearchQuery(data);
            }   
        }
    };

    const renderRendicion = ({ item }) => {
        const isSelected = (selectedId === item.idCentroCosto);
        const textColor = isSelected ? '#0A834B' : '#353D4A';
        const fontWeight = isSelected ? "bold" : "normal";
        const Ellipse = isSelected ? <SvgFormatter SvgPath={EllipseCheckSVG} style={styles.icons} /> : <SvgFormatter SvgPath={EllipseSVG} style={styles.icons} />;

        if (item.idCentroCosto != null) {
            return (
                <TouchableOpacity style={[styles.fraction]} onPress={() => SelectItem(item)} key={item.idCentroCosto}>
                    <Text style={[styles.greenText, { color: textColor, fontWeight: fontWeight }]}>{item.descripcion}</Text>
                    <View style={styles.textFraction}>
                        {Ellipse}
                    </View>
                </TouchableOpacity>
            );
        }
    };

    const GastoRepetido = () => {

        if(rendicion.esNueva)
        {
            return false;
        }

        var centroCostoId = rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].centroCostoId;
        var categoriaId = rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].categoriaId;

        const countTypes = rendicion.DATA.desgloseGasto.filter(x => x.centroCostoId == centroCostoId && x.categoriaId == categoriaId);
        return (countTypes.length > 1);
    }

    async function FetchExisteDesgloseSimilar(tipoGastoId, fechaDesglose) {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/gastos/verificar/desglosesimilar/";        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify({
                TipoGastoId: tipoGastoId,                
                FechaDesglose: fechaDesglose,
                Legajo: legajo
            })            
        });
        
        const data = await response.json();        
        return data.existe;    
    }

    const CheckDesgloseSimilar = async () => {
        const fechaDesglose = rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose;                
        for(let i=0; i < (rendicion.DATA.desgloseGasto.length - 1); i++)
        {  
            var desgloseGasto = rendicion.DATA.desgloseGasto[i];            
            if(fechaDesglose == desgloseGasto.FechaDesglose)
            {                
                return true;
            }
        }
        
        return await FetchExisteDesgloseSimilar(rendicion.DATA.tipoGastoId, fechaDesglose);
    }

    async function CheckDiaNoHabil() {
        const fechaDesglose = rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose;
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/gastos/verificar/dianohabil/";        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify({                
                Fecha: fechaDesglose                
            })            
        });
        
        const data = await response.json();        
        return data.noHabil;    
    }
    
    const AsyncAlert = (title, msg, okText, cancelText, cancelable) => {
        return new Promise((resolve, reject) => {
            Alert.alert(
                title,
                msg,
                [
                    {text: okText, onPress: () => resolve(true) },
                    {text: cancelText, onPress: () => resolve(false) }
                ],
                { cancelable: cancelable }
            )
        })
    }    

    const ValidarSiguiente = async () => {
        if (!selectedId)
        {
            setTextwarning("Seleccione un centro de costo.");
            return;
        }
        if (GastoRepetido() == true)
        {
            setTextwarning("Categoria y centro de costo repetido.");
            return;
        }

        if(rendicion.esNueva)
        {
            if(rendicion.gastoEnCantidad){
                if(rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose == undefined)
                {
                    rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose= AddZerosToDate(date);
                }                                
                var fechaDesglose = rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose;
                
                if(await CheckDiaNoHabil() == true){
                    if(Platform.OS == "web")
                    {
                        if (!confirm("La fecha ingresada es un día no hábil " + fechaDesglose + " ¿Desea continuar de todos modos?"))
                        {
                            return;
                        }   
                    }
                    else{                        
                        const userResponse = await AsyncAlert("Día no hábil", "La fecha ingresada es un día no hábil " + fechaDesglose + " ¿Desea continuar de todos modos?", "Continuar", "Volver", false);
                        
                        if(!userResponse){
                            return;
                        }
                    }
                }

                // Aca validación para chequear si existe desglose similar                                
                if(await CheckDesgloseSimilar() == true)
                {
                    if(Platform.OS == "web")
                    {
                        if (!confirm("Ya existe un desglose similar con fecha " + fechaDesglose + " ¿Desea continuar?"))
                        {
                            return;
                        }   
                    }
                    else{                        
                        const userResponse = await AsyncAlert("Desglose similar existente", "Ya existe un desglose similar con fecha " + fechaDesglose + " ¿Desea continuar?", "Continuar", "Volver", false);
                        
                        if(!userResponse){
                            return;
                        }
                    }                   
                }
                
                if(rendicion.DATA.KmReconocidosVehiculo != null && rendicion.DATA.KmReconocidosVehiculo != undefined && rendicion.DATA.KmReconocidosVehiculo != 0 && rendicion.DATA.KmReconocidosVehiculo < Number(importe))
                {
                    if(Platform.OS == "web")
                    {
                        if (!confirm("La cantidad de km ingresados supera los reconocidos para el vehículo seleccionado - km reconocidos: " + rendicion.DATA.KmReconocidosVehiculo + " ¿Desea continuar?"))
                        {
                            return;
                        }   
                    }
                    else{                        
                        const userResponse = await AsyncAlert("Kilómetros reconocidos superado","La cantidad de km ingresados supera los reconocidos para el vehículo seleccionado - km reconocidos: " + rendicion.DATA.KmReconocidosVehiculo + " ¿Desea continuar?", "Continuar", "Volver", false);
                        
                        if(!userResponse){
                            return;
                        }
                    }                   
                }
            }                        
        }

        var SumImporte = 0;
        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
            SumImporte = SumImporte + Number(desgloseGasto.importe);
        }
        SumImporte = SumImporte + Number(importe);
        if (Number(importe) > 0)
        { 
            if ((SumImporte <= rendicion.importe) || rendicion.esNueva)
            {
                rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].importe = importe;
                                 
                if(rendicion.esNueva)
                {                                        
                    navigation.navigate('NuevaRendicion', rendicion);                    
                }
                else
                {
                    navigation.navigate('GestionDesglose', rendicion);
                }
                
            } else
            {        
                {(rendicion.gastoEnCantidad) ? setTextwarning("La cantidad total no puede superar el gasto.") : setTextwarning("El importe total no puede superar el gasto.")}        
            };
        }else
        {
            {(rendicion.gastoEnCantidad) ? setTextwarning("La cantidad debe ser positiva.") : setTextwarning("El importe debe ser positivo.")}        
        };
    }

    function DateTimePickerWeb(value, id) {                
        return React.createElement('input', {
          type: 'date',
          defaultValue:  AddZerosToDate(value),      
          id: id,
          onChange: handleChange,
          style: {borderColor: 'gray', fontWeight: 'bold', fontSize: 12, marginLeft: 5, padding: 2, fontFamily: "Gotham", height: 40, width: 200, borderWidth: 1, alignSelf: 'center', textAlign: 'center'},
          required: "required"
        })
    }

    function AddZerosToDate(date) {          
        var dateString;                
        dateString = date.getFullYear()
                    + '-' + ('0' + (date.getMonth()+1)).slice(-2)             
                    + "-" + ('0' + date.getDate()).slice(-2);             
        
        return dateString;
    }

    function handleChange(e){  
        var fechaActual = new Date(); 
        var timestamp = Date.parse(e.currentTarget.value);
        var dateObject = new Date(timestamp);
        if(dateObject > fechaActual){
            document.getElementById(e.currentTarget.id).value = AddZerosToDate(fechaActual);
        }
        
        const [year, month, date] = e.currentTarget.value.split("-");
        const dateString = date + "-" + month + "-" + year;
        const dateSeleccionada = new Date( dateString.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") );
        
        setDate(dateSeleccionada);
        rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose = AddZerosToDate(dateSeleccionada);        
    }

    const handleFecha = async () => {
        setShow(true);
    }

    const onChange = (event, selectedDate) => {          
        const currentDate = selectedDate || date;        
        setShow(Platform.OS === 'ios');
        setDate(currentDate);
        
        rendicion.DATA.desgloseGasto[rendicion.DATA.desgloseGasto.length - 1].FechaDesglose = AddZerosToDate(currentDate);
    };

    return (
        <Fragment>          
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <FlatList  ListFooterComponent={ 
                <View style={[styles.container,{minHeight: styleHeight}]}>
                    <View style={styles.card}>
                        <View style={{flexDirection:'row'}}>
                        <TouchableOpacity onPress={handleVolver}>
                                { Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center'}}/> : <ArrowSVG/> }
                        </TouchableOpacity>
                        {Platform.OS !== 'web' ? <HeaderCard style={{height:30}} whiteColor={false}/> : <></>}
                        </View>
                      
                        <View style={{flexDirection:'row'}}>
                        <View>
                            <Text style={styles.title}>Seleccionar Centro Costo</Text>
                            <Text style={styles.categories}>Generar Rendición</Text>
                        </View>
                       {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                          <HeaderCard />
                        </View> : <></>}
                        </View>
                        {rendicion.esNueva  == true ?
                            <></>
                            :
                            <ResumenRendicion datosRendicion={rendicion}/>             
                        }                        
                        <View>
                            <Searchbar
                                placeholder="Buscar"
                                selectionColor='green'
                                style={{
                                    backgroundColor: '#FAFAFA', 
                                    elevation: 0,
                                    fontWeight: 'bold', 
                                    fontSize: 0
                                    }}
                                onChangeText={searchFilterFunction}
                                    icon={() => <SvgFormatter SvgPath={ListSVG} />}
                                autoCorrect={false} 
                                inputAccessoryViewID={inputAccessoryViewID}
                            />
                            <Divider style={styles.horizontalLine}/>
                            {loading ? <ActivityIndicator size="large" color="#00ff00" />:
                                Platform.OS !== 'web' ?
                                <FlatList 
                                data={searchQuery}
                                renderItem={renderRendicion}
                                keyExtractor={rendi => `${rendi.idCentroCosto}`}
                                extraData={selectedId} />: 
                                <FlatList 
                                 data={searchQuery}
                                renderItem={renderRendicion}
                                keyExtractor={rendi => `${rendi.idCentroCosto}`}
                                extraData={selectedId}
                                 initialNumToRender={searchQuery.length}
                                maxToRenderPerBatch={searchQuery.length}
                                windowSize={searchQuery.length} />  }
                           
                        </View>
                            <KeyboardDone inputAccessoryViewID={inputAccessoryViewID} />
                        <View style={styles.containerImporte}>
                            <View style={{flexDirection:'column', marginRight: '2%'}}>
                                <View style={{ alignSelf: 'center' }}>
                                        {(rendicion.gastoEnCantidad) ? <Text style={{ fontWeight: 'bold' }}>Cantidad: </Text> : <Text style={{ fontWeight: 'bold' }}>Importe: </Text>}
                                </View>
                            
                                <View>
                                    <TextInput style={styles.textImporte}
                                        onChangeText={text => setImporte(text.replace(/[^0-9]/g, ''))}
                                            value={importe.toString()}
                                        />                                                                    
                                </View>
                            </View>
                            {rendicion.gastoEnCantidad ?
                                        <View>                                            
                                            { Platform.OS == 'web' ?
                                                <View style={styles.containerFecha}>
                                                    <Text style={{ fontWeight: 'bold', alignSelf: 'center' }}>Fecha del desglose:</Text>
                                                    {DateTimePickerWeb(new Date(),"fechaDesglose")}
                                                </View>
                                                :
                                                <></>
                                            }
                                        </View>
                                        :
                                        <></>
                            }
                        </View>
                        {rendicion.gastoEnCantidad ?
                            <View>
                                { Platform.OS != 'web' ?
                                    <View style={styles.containerFechaMobile}>
                                        <View style={{flexDirection:'row'}}> 
                                            <Text style={{ fontWeight: 'bold', alignSelf: 'center' }}>Fecha del desglose</Text>
                                        </View>
                                        <View style={{flexDirection:'row'}}>
                                            <TouchableOpacity onPress={handleFecha}>
                                                <Text style={styles.labelFecha}>{ date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()}</Text>
                                            </TouchableOpacity>  
                                        </View>                              
                                        {show && (                                        
                                            <DateTimePicker
                                            testID="dateTimePicker"
                                            value={date}
                                            maximumDate={new Date()}
                                            mode={mode}
                                            is24Hour={true}
                                            display="default"
                                            onChange={onChange}                                        
                                            />                                        
                                        )}
                                
                                    </View>
                                    : <></>
                                }
                            </View>                            
                        :
                        <></>
                        }
                        <View>
                            <Text style={styles.textWarning}>{textwarning}</Text> 
                            <TouchableOpacity style={styles.btnSiguiente} onPress={() => ValidarSiguiente()}>
                            <Text style={styles.textSiguiente}>Siguiente</Text>
                            </TouchableOpacity>
                        </View>                                
                    </View>       
                </View>
                }/>
            </SafeAreaView>
        </Fragment>
      );
}
export default CentroCosto;