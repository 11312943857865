
import React, { useCallback, useState, Fragment } from 'react';
import { Text } from 'react-native-paper';
import ResumenRendicion from '../../components/ResumenRendicion';
import { TouchableOpacity, View, FlatList, Dimensions, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Categoria/arrow.svg'
import GreenCloseSVG from '../../resources/GestionDesglose/green-close.svg'
import GreenPlusSVG from '../../resources/GestionDesglose/green-plus.svg'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import KeyboardDone from '../../../keyboardDone';
import SvgFormatter from '../../../svgFormatter';
import HeaderCard from '../../components/header-tarjeta';

const GestionDesglose = (status) => {
    const inputAccessoryViewID = 'done';
    let styleHeight = Dimensions.get('window').height
    const navigation = useNavigation();
    const rendicion = status.route.params;
    rendicion.estado = rendicion.estadoAnterior;
    const [desglose, setDesglose] = useState([])

    const handleVolver = useCallback(() => {
        navigation.pop();
    })

    navigation.addListener('focus', () => {
        setDesglose(rendicion.DATA.desgloseGasto);
        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
           if(Number(desgloseGasto.importe) == 0){
                removeItem(desgloseGasto);
           }
        }
    });

    const ValidarSiguiente = () => {
        var SumImporte = 0;
        for (let desgloseGasto of rendicion.DATA.desgloseGasto) {
            SumImporte = SumImporte + Number(desgloseGasto.importe);
        }
        return (rendicion.importe == SumImporte);
    }

    const renderDesgloseWeb = (item) => (
        <View style={styles.cardRendicionWeb} key={item.centroCostoId + item.categoriaId}>
            <Text style={[styles.textCardRendicion]}>Categoria: {item.categoriaDes}</Text>
            <Text style={[styles.textCardRendicion]}>Centro decosto: {item.centroCostoDes}</Text>
            <Text style={[styles.textCardRendicion]}>Importe: {rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text>
            <TouchableOpacity onPress={() => removeItem(item)}>
                <img src={GreenCloseSVG}/>
            </TouchableOpacity>
        </View>
    );

    const renderDesglose = (item) => (
        <View style={styles.cardRendicion} key={item.centroCostoId + item.categoriaId}>
            <Text style={[styles.textCardRendicion]}>{item.categoriaDes}</Text>
            <Text style={[styles.textCardRendicion]}>{item.centroCostoDes}</Text>
            <Text style={[styles.textCardRendicion]}>{rendicion.moneda == 'USD' ? 'USD$' : '$'}{item.importe}</Text>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => removeItem(item)}>
                <GreenCloseSVG />
            </TouchableOpacity>
        </View>
    );

    const removeItem = (item) => {
        const newList = rendicion.DATA.desgloseGasto.filter((r) => r.categoriaId !== item.categoriaId || r.centroCostoId !== item.centroCostoId);
        rendicion.DATA.desgloseGasto = newList;
        setDesglose(newList);
    }

    return (
        <Fragment>
            <SafeAreaView style={{ backgroundColor: '#075330' }}>
                <FlatList ListFooterComponent={
                    <View style={[styles.container, { minHeight: styleHeight }]}>
                        <View style={styles.card}>
                            <View style={{flexDirection:'row'}}>
                            <TouchableOpacity onPress={handleVolver}>
                                {Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center' }} /> : <ArrowSVG />}
                            </TouchableOpacity>
                                {Platform.OS !== 'web' ? <HeaderCard style={{height:30}}/> : <></>}
                            </View>
                            
                            <View style={{flexDirection:'row'}}>
                                <View>
                                    <Text style={styles.title}>Gestionar Desglose</Text>
                                    <Text style={styles.categories}>Generar Rendición</Text>
                                </View>
                               {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                                    <HeaderCard />
                                </View> : <></>}
                            </View>
                            <ResumenRendicion datosRendicion={rendicion} />
                            <View>
                                {Platform.OS == "web" ?
                                    <View>{desglose.map(r => renderDesgloseWeb(r))}</View> :
                                    <View>{desglose.map(r => renderDesglose(r))}</View>}                                
                            </View>
                            <View>
                                {ValidarSiguiente()==true ? 
                                    <TouchableOpacity style={styles.btnSiguiente} onPress={() => navigation.navigate('AdjuntanteTicket', rendicion)}>
                                        <Text style={styles.textSiguiente}>Siguiente</Text>
                                    </TouchableOpacity>
                                    :
                                    <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => navigation.navigate('Categoria', rendicion)}>
                                        {Platform.OS == "web" ?
                                            <img src={GreenPlusSVG} style={{ width: '80px' }} />
                                            : <SvgFormatter SvgPath={GreenPlusSVG} />} 
                                    </TouchableOpacity>                                   
                                }
                                <Text style={styles.warning}>Debe rendir el total del gasto para avanzar.</Text> 
                            </View>
                            <KeyboardDone inputAccessoryViewID={inputAccessoryViewID} />
                        </View>
                    </View>
                } />
            </SafeAreaView>
        </Fragment>
    );
}
export default GestionDesglose;