import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import styles from './styles';

const LoadScreen = (props) => {
    return (
        <View style={[styles.container, styles.horizontal]}>
            <ActivityIndicator size="large" color="#00ff00" />
        </View>
    );
}

export default LoadScreen;