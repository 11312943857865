
import React, { useContext, useState, useEffect,Fragment} from 'react';
import { Text, Divider} from 'react-native-paper';
import { TouchableOpacity, View, ScrollView, FlatList, ActivityIndicator, Dimensions, Platform } from 'react-native'
import styles from './styles'
import DoorSVG from '../../resources/Tarjetas/door-open-fill.svg'
import CreditCardSVG from '../../resources/menu Lateral/CreditCard.svg'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import { AuthContext } from '../app/context';
import { CardContext } from '../../context/CardContext'
import { LegajoContext } from '../../context/LegajoContext';

import AsyncStorage from '@react-native-async-storage/async-storage';


const Tarjetas = () => {
    const { signOut } = React.useContext(AuthContext);

    let styleHeight = Dimensions.get('window').height
    const navigation = useNavigation();
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [textwarning, setTextwarning] = useState(false);
    const { setCard } = useContext(CardContext);
    const { setLegajo } = useContext(LegajoContext);

    const handleSignout = () => {
        if (Platform.OS == "web"){
            AsyncStorage.removeItem("@storage_Key").then(() => {
                window.location.reload();
            });
        }else{
            AsyncStorage.removeItem("@storage_Key").then(() => {
                signOut();
            });
        }
    }
    const SelectItem =  (prop) =>{
        setTextwarning(false);
        setSelectedId(prop.legajo);
        setCard(prop.tarjeta);
        setLegajo(prop.legajo);
    }
  
     //Llamada a la API
     useEffect(() => {
        async function fetchData() {
            const userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(API_URL + '/Legajos', {
                headers: {
                    "Authorization": `Bearer ${userToken}`
                }
            });
            const data = await response.json();
            setDatos(data);
            setLoading(false);
        }
        fetchData();
    }, []);

    const renderTarjeta = ({ item }) => {
        const isSelected = (selectedId === item.legajo);
        const textColor = isSelected ? '#353D4A' : '#0A834B';
        const fontWeight = isSelected ? "normal" : "bold";
        const backgroundColor = isSelected ? '#d3d3d3' : 'white';

        if(item.tarjeta.numeroTarjeta != null)
        {

        }
        /*const lengthNumeroTarjeta = item.tarjeta.numeroTarjeta.toString().length;
        const cuatroUltimosNumeros = item.tarjeta.numeroTarjeta.toString().substring(lengthNumeroTarjeta-4,lengthNumeroTarjeta);
        const numeroTarjetaAMostrar = "XXXX-XXXX-XXXX-" + cuatroUltimosNumeros;*/
        var nombre = item.nombre;
        
        if(nombre == "" || nombre == null){
            nombre = item.legajo;            
        }
        else
        {
            nombre += " - " + item.legajo;
        }

        item.tarjeta.nombre = nombre;

        return (
            <TouchableOpacity  onPress={()=>SelectItem(item)} key={item.legajo}>
                <View style={[styles.cardRendicion, {backgroundColor: backgroundColor}]}>
                    <View style={[styles.fraction]}>
                        <SvgFormatter SvgPath={CreditCardSVG} styles={styles.CreditCard} />
                        <View style={{flexDirection:'column', marginLeft:20}}>
                            <Text style={[styles.greenText , { color: textColor, fontWeight: fontWeight}]}>{nombre}</Text>
                            {/* <Text style={styles.textNumero}>{numeroTarjetaAMostrar}</Text> */}
                        </View> 
                    </View>
                </View>
            </TouchableOpacity>
         );
    }

    const ValidarSiguiente = () => {
        if (!selectedId) {
            setTextwarning(true);
            return;
        }
        navigation.navigate('TipoRendicion');
    }

    return (
        <Fragment>          
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <FlatList  ListFooterComponent={ 
                <View style={[styles.container,{minHeight: styleHeight}]}>
                    <View style={styles.card}>
                        <TouchableOpacity onPress={handleSignout}>
                                { Platform.OS == "web" ? <img src={DoorSVG} style={{ alignSelf: 'center', width: 32, height: 32}}/> : 
                                <DoorSVG style={{alignSelf:'center'}}/> }
                        </TouchableOpacity>
                        <View>
                            <Text style={styles.title}>Seleccione un usuario</Text>
                        </View>
                        <View>
                                <Divider style={styles.horizontalLine} />
                                <ScrollView style={{alignSelf:'center'}} scrollIndicatorInsets={{ top: 1, bottom: 1 }}
                                    contentInsetAdjustmentBehavior="always">
                                    <View style={{ minHeight: 250 }}>
                                    {loading ? <ActivityIndicator size="large" color="#00ff00" /> :
                                        <FlatList
                                        data={datos}
                                        renderItem={renderTarjeta}
                                        keyExtractor={card => `${card.idTarjeta}`}
                                        extraData={selectedId} /> 
                                    }
                                    </View>
                                </ScrollView>
                        </View>
                        <View style={{alignItems: 'center'}}>
                            {textwarning ? <Text style={styles.textWarning}>Seleccione una tarjeta.</Text> : <></>}   
                                <TouchableOpacity style={styles.btnSiguiente} onPress={() => ValidarSiguiente()}>
                            <Text style={styles.textSiguiente}>Siguiente</Text>
                            </TouchableOpacity>
                        </View>
                                
                    </View>       
                </View>
                }/>
            </SafeAreaView>
        </Fragment>
      );
}
export default Tarjetas;