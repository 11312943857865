import React, { createContext, useState } from "react"

export const LegajoContext = createContext({});

const LegajoContextProvider = ({ children }) => {
    const [legajo, setLegajo] = useState([])
  
    return (
        <LegajoContext.Provider value={{legajo, setLegajo}}>
            {children}
        </LegajoContext.Provider>
    )
}
export default LegajoContextProvider