import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems:'center',
        justifyContent:'center',
    },
    cardGrey: {
        flex: 1,
        width: 190,
        marginBottom: 10,
        borderRadius: 20,
        borderColor: '#353D4A',
        borderWidth: 1,
        padding: 10
    },
    cardWhite: {
        flex: 1,
        width: 190,
        marginBottom: 10,
        borderRadius: 20,
        borderColor: '#f2f2f2',
        borderWidth: 1,
        padding: 10
    },
    tituloGrey: {
        color: '#353D4A',
        fontWeight: '500',
        fontFamily: 'Gotham',
        marginLeft: 5
    },
    tituloWhite: {
        color: '#f2f2f2',
        fontWeight: '500',
        fontFamily: 'Gotham',
        marginLeft: 5
    },
    subcontainer: {
        flexDirection: "row",
        marginTop: 10,
        alignContent: 'flex-start'
    },
    textNombreGrey: {
        fontSize: 12,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: 0,
        marginBottom: 10,
        fontWeight: 'bold',
        fontFamily: 'Gotham'

    },
    textNombreWhite: {
        fontSize: 12,
        color: '#f2f2f2',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: 0,
        marginBottom: 10,
        fontWeight: 'bold',
        fontFamily: 'Gotham'

    },
    textNumero: {
        fontSize: 14,
        color: '#353D4A',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
        fontWeight: '500'
    },
    horizontalLineGrey: {
        borderBottomColor: '#353D4A',
        borderBottomWidth: 1
    },
    horizontalLineWhite: {
        borderBottomColor: '#f2f2f2',
        borderBottomWidth: 1
    },
    greyColor:{
        color: '#353D4A',
        borderColor: '#353D4A'
    },
    greyBorderBottomColor:{
        borderBottomColor: '#353D4A'
    },
    whiteColor:{
        color: '#f2f2f2',
        borderColor: '#f2f2f2'
    },
    whiteBorderBottomColor:{
        borderBottomColor: '#f2f2f2'
    }
});

export default styles;