
import React, {useState, useContext, useEffect } from 'react'
import { Text } from 'react-native-paper'
import { View, Platform, TouchableOpacity, Alert } from 'react-native'
import styles from './styles'
import GreenApprovedSVG from '../../resources/Detalle de gasto/GreenApproved.svg'
import DesconocidoSVG from '../../resources/Detalle de gasto/BlueUnknown.svg'
import PendingSVG from '../../resources/Estado/pending.svg'
import Trash from '../../resources/Detalle de gasto/trash.svg'
import Send from '../../resources/Detalle de gasto/send.svg'
import { Tooltip } from 'react-native-elements';
import { API_URL } from '../../config/constants';
import AsyncStorage from '@react-native-async-storage/async-storage';


import SvgFormatter from '../../../svgFormatter';
import { useNavigation } from '@react-navigation/native';

const ResumenOtraRendicion = (props) => {

    const navigation = useNavigation();

    const key = props.datosRendicion.id;
    const estado = props.datosRendicion.estado;
    const estadoFull = props.datosRendicion.estadoFull;
    const numeroRendicion = props.datosRendicion.numeroRendicion;
    const nombreEmpresa = props.datosRendicion.titulo;
    const moneda = props.datosRendicion.moneda;
    const precio = props.datosRendicion.importe;
    const fecha = props.datosRendicion.fecha;
    var TextPendiente = <Text />
    var estadoSVG = <PendingSVG />

    switch (estado) {
        case 'Pendiente':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estadoFull}</Text>
            break;
        case 'Anulada':
            estadoSVG = <SvgFormatter SvgPath={PendingSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;
        case 'Rendido':
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#008B47' }]}>{estadoFull}</Text>
            break;
        case 'Desconocido':
            estadoSVG = <SvgFormatter SvgPath={DesconocidoSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#2759CB' }]}>{estadoFull}</Text>
            break;
        default:
            estadoSVG = <SvgFormatter SvgPath={GreenApprovedSVG} style={styles.icons} />
            TextPendiente = <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{estado}</Text>
            break;

    }

    const eliminarRendicion = async () => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/eliminar/" + key;
        const response = await fetch(url, {
            method: 'DELETE', 
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${userToken}`
            },
            body: null
        });
        const data = await response.json( );

        const refresh =  props.refreshList;
        refresh();
    };
    
    const enviarRendicion = async () => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/otrarendicion/" + key;
        const response = await fetch(url, {
            method: 'PUT', 
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${userToken}`
            },
            body: null
        });
        const data = await response.json( );
        
        const refresh =  props.refreshList;
        refresh();
    };

    const confirmEliminarRendicion = () => {
        if(Platform.OS === 'web'){
            if (confirm("¿Desea eliminar la rendición?")) {
                eliminarRendicion();
            }
            else{
                return;
            }
        }
        else{
            createTwoButtonAlert("Eliminar rendición", "¿Desea eliminar la rendición?", "Eliminar", "Cancelar", true, eliminarRendicion,"");
        }
    };

    const confirmEnviarRendicion = () => {
        if(Platform.OS === 'web')
        {
            if (confirm("¿Desea enviar la rendición?")) {
                enviarRendicion();
            }
            else{
                return;
            }
        }
        else
        {
            createTwoButtonAlert("Enviar rendición", "¿Desea enviar la rendición?", "ENVIAR", "Cancelar", true, enviarRendicion,"");
        }        
    };

    const createTwoButtonAlert = (title, msg, okText, cancelText, cancelable, onPressOk, onPressCancel) =>
    Alert.alert(
      title,
      msg,
      [
        {
          text: cancelText,
          onPress: () => onPressCancel(),
          style: "cancel"
        },
        { text: okText, onPress: () => onPressOk() }
      ],
      { cancelable: cancelable }
    );
      
    return (
    <View>
        <View style={styles.cardRendicion}>
            {estadoSVG}
            {
                Platform.OS !== 'web' ?
                    <View style={{ width: 100 }}>
                        <Tooltip backgroundColor='#008B47' popover={<Text style={[styles.tooltipText]}>{nombreEmpresa}</Text>}>
                            <Text numberOfLines={1} ellipsizeMode='tail' style={[styles.textCardRendicion, { color: '#353D4A' }]}>{nombreEmpresa}</Text>
                        </Tooltip>
                        {TextPendiente}
                        {numeroRendicion == null || "" ? <></> : 
                                    <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{numeroRendicion}</Text>}
                    </View>
                    :
                    <View style={{ width: "10%" }}>
                        <Text style={[styles.textCardRendicion, { color: '#353D4A' }]}>{nombreEmpresa}</Text>
                        {TextPendiente}
                        {numeroRendicion == null || "" ? <></> : 
                                    <Text style={[styles.textCardRendicion2, { color: '#C72141' }]}>{numeroRendicion}</Text>}
                    </View>
            }
            <View style={{flexDirection: 'row', width: 60}}>
                {estado == "Pendiente" ?
                    <TouchableOpacity style={styles.icons} onPress={confirmEnviarRendicion}>
                        <SvgFormatter SvgPath={Send} />
                    </TouchableOpacity>
                    :
                    <></>
                }

                {estado == "Pendiente" || estado =="Enviada" ?
                    <TouchableOpacity style={styles.icons} onPress={confirmEliminarRendicion}>
                        <SvgFormatter SvgPath={Trash} />
                    </TouchableOpacity>
                    :
                    <></>
                }

                
            </View>
            <View style={{ width: 100 }}>
                {(props.datosRendicion.tipoGasto.mideEnCantidad == true) ? 
                <Text style={[styles.textCardRendicion, { color: '#353D4A' }]}>
                    {precio} 
                </Text> : 
                <Text style={[styles.textCardRendicion, { color: '#353D4A' }]}>
                    {moneda == 'USD' ? 'USD$' : '$'}{precio} 
                </Text>}
                <Text style={[styles.textCardRendicion2, { color: '#B1B5BE' }]}>{fecha}</Text>
            </View>
        </View>  
    </View>
    );
}

export default ResumenOtraRendicion;