
import React, { useCallback, useState, useEffect,Fragment, useContext} from 'react';
import { Text, Divider} from 'react-native-paper';
import { TouchableOpacity, View, ScrollView, FlatList, ActivityIndicator, Dimensions, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Categoria/arrow.svg'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { API_URL } from '../../config/constants';
import KeyboardDone from '../../../keyboardDone';
import SvgFormatter from '../../../svgFormatter';
import HeaderCard from '../../components/header-tarjeta'
import { LegajoContext } from '../../context/LegajoContext';
import CarSVG from '../../resources/MisGastos/Car.svg'

import AsyncStorage from '@react-native-async-storage/async-storage';


const Vehiculo = ( status ) => {
    const {legajo} = useContext(LegajoContext);
    const inputAccessoryViewID = 'done';
    let styleHeight = Dimensions.get('window').height;
    let platformHeight = Platform.OS == 'web' ? 250 : 100;
    const navigation = useNavigation();
    const rendicion = status.route.params;
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);
    rendicion.estado = rendicion.estadoAnterior;
    const [textwarning, setTextwarning] = useState("");
    
    const handleVolver = useCallback(() => {
        navigation.pop();
    })

    const SelectItem =  (prop) =>{
        setSelectedId(prop.id)        
    }
     //Llamada a la API
    const fetchData = async () => {        
        const userToken = await AsyncStorage.getItem('@storage_Key');
        const response = await fetch(API_URL + '/Legajos/Vehiculos/' + legajo, {
            headers: {
                "Authorization": `Bearer ${userToken}`
            }
        });
        const data = await response.json();
        setDatos(data);        
        setLoading(false);
    }
    useEffect(() => {
        fetchData()
    },[])            

    function handleVehiculo (prop) {                
        rendicion.DATA.idVehiculo = prop.item.idVehiculo;
        rendicion.DATA.KmReconocidosVehiculo = prop.item.kM_Reconocidos;
        
        navigation.navigate('NuevaRendicion', rendicion);        
    };

    const renderVehiculo = ({ item }) => {                     
        return (
            <View style={[styles.cardVehiculo]}>                                            
                <TouchableOpacity style={[styles.fraction]} onPress={() => handleVehiculo({item})}>                                
                    <SvgFormatter SvgPath={CarSVG}/>
                    <View>
                    <View style={{flexDirection:'row', marginLeft:20, marginTop:4}}>                                                        
                        <Text style={styles.textVehiculo}>{item.descripcion}</Text>
                    </View>
                    <View style={{flexDirection:'row', marginLeft:20, marginTop:4}}>                                                        
                        <Text style={styles.textDominio}>{item.dominio}</Text>
                    </View>
                    </View>
                </TouchableOpacity>
            </View>
         );
    }

    return (
        <Fragment>          
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <ScrollView scrollEnabled={false}>
                <View style={[styles.container,{minHeight: styleHeight}]}>
                    <View style={styles.card}>
                        <View style={{flexDirection:'row'}}>
                        <TouchableOpacity onPress={handleVolver}>
                                { Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center'}}/> : <ArrowSVG/> }
                        </TouchableOpacity>
                        {Platform.OS !== 'web' ? <HeaderCard style={{height:30}} whiteColor={false}/> : <></>}
                        </View>
                       
                        <View style={{flexDirection:'row'}}>
                        <View>
                            <Text style={styles.title}>Seleccionar Vehículo</Text>
                            <Text style={styles.categories}>Generar Rendición</Text>
                        </View>
                        {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                          <HeaderCard />
                        </View> : <></>}
                        </View>                        
                        <View>                            
                            <View style={{marginTop:"3%"}}>
                                <ScrollView style={{alignSelf:'center'}} scrollIndicatorInsets={{ top: 1, bottom: 1 }}
                                        contentInsetAdjustmentBehavior="always">
                                        {loading ? <ActivityIndicator size="large" color="#00ff00" /> :
                                            <View style={{ minHeight: 250 }}> 
                                                {datos.length == 0 ?
                                                <Text style={styles.textSinVehiculo}>No existen vehículos activos para el legajo seleccionado.</Text>
                                                :
                                                    <FlatList
                                                    data={datos}
                                                    renderItem={renderVehiculo}
                                                    keyExtractor={v => `${v.idVehiculo}`}
                                                    extraData={selectedId} />
                                                }                                                
                                            </View>
                                        }
                                        
                                </ScrollView>                                
                            </View>      
                        </View>
                        <KeyboardDone inputAccessoryViewID={inputAccessoryViewID}/>                                                        
                    </View>       
                </View>
                </ScrollView>
            </SafeAreaView>
        </Fragment>
      );
}
export default Vehiculo;