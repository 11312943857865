import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#075330',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FAFAFA',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        fontFamily: 'Gotham-Bold',
        color: '#353D4A',
        fontSize: 30,
        marginBottom: 16,
        marginTop: 20
    },
    categories: {
        fontFamily: 'Gotham-Bold',
        color: '#B1B5BE',
        fontSize: 14,
        marginBottom: 12
    },
    icons: {
        marginRight: 20,
        width: 30
    },
    cardRendicion: {
        //height: 50,
        marginVertical: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },

    textCardRendicion: {
        fontFamily: 'Gotham',
        fontSize: 15,
    },
    textCardRendicionMinor: {
        fontFamily: 'Gotham',
        fontSize: 13,
        paddingRight: 12
    },
    textCardRendicion2: {
        marginTop: 3,
        fontSize: 14,
        fontFamily: 'Gotham'
    },
    tooltipText: {
        color: '#fff',
        fontSize: 12,
        fontFamily: 'Gotham-Bold'
    }
})
export default styles;