import React,{useCallback} from 'react'
import { View, Dimensions,TouchableOpacity, Text } from 'react-native'
import { TabView, TabBar } from 'react-native-tab-view'
import styles from './styles'
import ResumenRendicion from '../ResumenRendicion'
import { useNavigation } from '@react-navigation/native'

import SvgFormatter from '../../../svgFormatter';

const TabTodas = props => {

    const navigation = useNavigation();
    return (
        <View style={styles.scene}>
            {props.rendiciones.map(rendicion => (
                rendicion.estado == 'Pendiente' || rendicion.estado == 'Anulada' ?
                    <TouchableOpacity onPress={() => navigation.navigate('Estado', rendicion)} key={rendicion.id} >
                        <ResumenRendicion datosRendicion={rendicion} />
                    </TouchableOpacity> :
                    <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
                        <ResumenRendicion datosRendicion={rendicion} />
                    </TouchableOpacity>
            ))}
        </View>
    );
}
const TabPendientes = props => {

  const navigation = useNavigation();
  return (
    <View style={styles.scene}>
        {props.rendiciones.map( rendicion =>(
           rendicion.estado == 'Pendiente' &&
              <TouchableOpacity 
                onPress={()=>navigation.navigate('Estado', rendicion)} 
                key={Math.random()} >
                  <ResumenRendicion datosRendicion={rendicion} />
              </TouchableOpacity>
          ))
      }
      {props.rendiciones.map( rendicion =>(
           rendicion.estado == 'Anulada' &&
              <TouchableOpacity 
                onPress={()=>navigation.navigate('Estado', rendicion)} 
                key={rendicion.id} >
                  <ResumenRendicion datosRendicion={rendicion} />
              </TouchableOpacity>
          ))
      }
    </View>
);}
const TabRendidas  = props => { 

  const navigation = useNavigation();
  return( 
    <View style={styles.scene}>
      {props.rendiciones.map( rendicion =>(
         rendicion.estado != null && rendicion.estado != 'Pendiente' && rendicion.estado != 'Anulada' && rendicion.estado != 'Desconocido' && 
          <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
            <ResumenRendicion datosRendicion={rendicion} />
        </TouchableOpacity>
        ))
      }
    </View>
);}
const TabDesconocidas  = props => 
{ 

  const navigation = useNavigation();
  return( 
    <View style={styles.scene}>
      {props.rendiciones.map( rendicion =>(
         rendicion.estado == 'Desconocido' &&
          <TouchableOpacity onPress={() => navigation.navigate('DetalleRendiciones', rendicion)} key={Math.random()}>
           <ResumenRendicion datosRendicion={rendicion} />
        </TouchableOpacity>
        ))
      }
    </View>
);}

 
const initialLayout = { width: Dimensions.get('window').width };
 
 const  TabEstados= ( props ) => {

  const [index, setIndex] = React.useState(0);

  const [routes] = React.useState([
    { key: 'Todas', title: 'Todas' },
    { key: 'Pendientes', title: 'Pendientes' },
    { key: 'Rendidas', title: 'Rendidas'},
    { key: 'Desconocidas', title: 'Desconocidas'}

  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Todas':
        return <TabTodas handleMisGastosPage={props.handleMisGastosPageNavigate} rendiciones={props.rendiciones} />;
      case 'Pendientes':
        return <TabPendientes handleComprobanteNav={props.handleDetalleRendicionesNav} rendiciones={props.rendiciones} />;
      case 'Rendidas':
        return <TabRendidas  rendiciones={props.rendiciones} />
      case 'Desconocidas':
        return <TabDesconocidas  rendiciones={props.rendiciones} />      
      default:
        return null;
    }
  };

  const renderTabBar = props => (
    
    <TabBar
      {...props}
      indicatorStyle={styles.tabBarText}
      style={styles.tabBarBackground}
      tabStyle={{ width: 120 }}
      labelStyle={styles.posibleStatus}
      inactiveColor= '#B1B5BE'
      activeColor=  '#008B47'
      scrollEnabled={true}
      getLabelText={({ route }) => route.title}
      tabStyle={{ width: 'auto' }}
    />
  );

    
  return (
    <TabView
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      initialLayout={initialLayout}
      style={styles.base}
      renderTabBar={renderTabBar}
    />
  );
}
 


export default TabEstados