import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Text,Appbar } from 'react-native-paper';
import styles from './styles';
import { TouchableOpacity, View, Image, ScrollView, RefreshControl, ActivityIndicator, StyleSheet, Platform } from 'react-native';
import TabEstados from '../../components/TabEstadosOtrasRendiciones'
import HeaderCard from '../../components/header-tarjeta'
import SandwichSVG from '../../resources/Listar Gastos/ExpandSandwich.svg'
import LookupSVG from '../../resources/Listar Gastos/WhiteLookUp.svg'
import Toast from 'react-native-toast-message'
import GreenBackgroundApprovedSVG from '../../resources/MisGastos/ApprovedGreenBackground.svg'
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import { LegajoContext } from '../../context/LegajoContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DateTimePicker from '@react-native-community/datetimepicker';
import GreenPlusSVG from '../../resources/GestionDesglose/green-plus.svg'

const OtrasRendiciones = ({ navigation }) => {
    const [visible, setVisible] = useState(false);
    const [selecItem, setSelecItem] = useState(-1);
    const [rendiciones, setRendiciones] = useState([]);
    const [loadingSpiner, setLoadingSpiner] = useState(true);
    const [sinGastos, setSinGasto] = useState(true);
    const [refreshing, setRefreshing] = useState(false);    
    const {legajo} = useContext(LegajoContext);

    const defaultDesde =() => {        
        var date = new Date();
        date.setDate(date.getDate()-7);
        return new Date(date);
    }

    const [dateDesde, setDateDesde] = useState(new Date(defaultDesde()));
    const [modeDesde, setModeDesde] = useState('dateDesde');
    const [showDesde, setShowDesde] = useState(false);

    const [dateHasta, setDateHasta] = useState(new Date());
    const [modeHasta, setModeHasta] = useState('dateHasta');
    const [showHasta, setShowHasta] = useState(false);

    const handleDetalleRendiciones = useCallback(() => {
        navigation.navigate('DetalleRendiciones');
    })
    const handleVolverAlt = () => {
        navigation.navigate('Estado');
    }
    const handleBuscarGastos = useCallback(() => {
        navigation.navigate('BuscarGastos', rendiciones);
    })

    function changeVisibility(state) {
        setVisible(state);
    }

    function DateTimePickerWeb(value, id) {                
        return React.createElement('input', {
          type: 'date',
          defaultValue:  AddZerosToDate(value),      
          id: id,
          style: {fontFamily: "Gotham"},
          onChange: handleChangeDateWeb,
          required: "required"
        })
    }
    
    function AddZerosToDate(date) {          
        var dateString;                
        dateString = date.getFullYear()
                    + '-' + ('0' + (date.getMonth()+1)).slice(-2)             
                    + "-" + ('0' + date.getDate()).slice(-2);             
        
        return dateString;
    }

    const toastConfig = {
        'exito': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toast}>
                    <SvgFormatter SvgPath={GreenBackgroundApprovedSVG} style={{ marginRight: 10 }} />
                    <Text style={styles.toastText}>Tu rendición fue realizada {"\n"}con éxito.</Text>
                </View>
            </View>
        ),
        'error': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toastError}>
                    <Image source={require('../../resources/MisGastos/GreenBackgroundFailedPNG.png')} style={{ marginRight: 10, width: 30, height: 30 }} />
                    <Text style={styles.toastText}>Error al realizar rendición.</Text>
                </View>
            </View>
        ),
        'info': () => { },
        'any_custom_type': () => { }
    }
    const showToast = (isOk) => {
        try{
            switch (isOk) {
                case true:
                    Toast.show({ type: 'exito' });
                    return;
                case false:
                    Toast.show({ type: 'error' });
                    return;
            }
        }
        catch(e) { console.error(e); }        
    }    
           
    async function callToast() {
        try{ 
            await AsyncStorage.getItem('STATUS', async (err, result) => {
                if (result) {
                    await AsyncStorage.removeItem('STATUS').then(() => {
                        setTimeout(() => {
                            if (result != "200")
                                showToast(false);
                            else
                                showToast(true);
                        }, 2000);
                    });
                }
            });

        } catch(e) { console.error(e); }
        
    }
    callToast();

    useEffect(() => {
        if (selecItem === -1) {
            
            navigation.addListener('focus', () => {    
                
                var desde = dateDesde;
                var hasta = dateHasta;
                
                //Para persistir las fecha de busqueda desde y hasta al volver a la pantalla, tuve que utilizar el prop navigation, no encontré otra solución superadora
                if(navigation.fechaDesde == undefined){
                    navigation.fechaDesde == dateDesde;
                }
                else{
                    desde = navigation.fechaDesde;
                    setDateDesde(desde);                    
                }

                if(navigation.fechaHasta == undefined){
                    navigation.fechaHasta == dateHasta;
                }
                else{
                    hasta = navigation.fechaHasta;
                    setDateHasta(hasta);                    
                }
                fetchData(desde, hasta)
            });
        }
    }, [])

    const fetchData = async (fechaDesde, fechaHasta) => {
       
        
            var url = API_URL + '/Gastos/fecha';
            var userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${userToken}`
                },
                body: JSON.stringify({
                    FechaDesde: fechaDesde,
                    FechaHasta: fechaHasta,
                    Legajo: legajo

                })
            })
            const data = await response.json();
            setRendiciones(data);
            setLoadingSpiner(false)
            if (data.length == 0) {
                setSinGasto(true)

            } else {
                setSinGasto(false)
            }

        
    }

    const onItemChanged = async (item, index) => {
        setSelecItem(item.value);
        fetchData(item.value);
        //await AsyncStorage.setItem('@selecPeriodos', JSON.stringify(item.value));
    }

    const wait = timeout => {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    };
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        
        fetchData(dateDesde,dateHasta);

        wait(500).then(() => setRefreshing(false));
    }, []);

    const handleChangeDateWeb = (e) =>{  
        var fechaActual = new Date(); 
        var timestamp = Date.parse(e.currentTarget.value);
        var dateObject = new Date(timestamp);

        if(dateObject > fechaActual){
            document.getElementById(e.currentTarget.id).value = AddZerosToDate(fechaActual);
        }

        const [year, month, date] = e.currentTarget.value.split("-");
        const datePosta = date + "-" + month + "-" + year;
        const dateSeleccionada = new Date( datePosta.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") );
        var desde = dateDesde;
        var hasta = dateHasta;

        if(e.currentTarget.id == "fechaDesde"){
            setDateDesde(dateSeleccionada);
            desde = dateSeleccionada;
            navigation.fechaDesde = desde;
        }
        else{
            setDateHasta(dateSeleccionada);
            hasta = dateSeleccionada;
            navigation.fechaHasta = hasta;
        }
        
        rendicionesPorFecha(desde, hasta);
    }    

    const rendicionesPorFecha = async (desde, hasta) => {
               
        var url = API_URL + '/Gastos/fecha';
        var userToken = await AsyncStorage.getItem('@storage_Key');
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify({
                FechaDesde: desde,
                FechaHasta: hasta,
                Legajo: legajo
            })
        })
        const data = await response.json();
        setRendiciones(data);
}
    
    const onChangeDesdeMobile = (event, selectedDate) => {   
        
        if(selectedDate == undefined){
            setShowDesde(false);
            return;
        }
        
        const currentDate = selectedDate || date;        
        setShowDesde(Platform.OS === 'ios');
        setDateDesde(currentDate);
        navigation.fechaDesde = currentDate;

        rendicionesPorFecha(selectedDate, dateHasta);
    };                

    const onChangeHastaMobile = (event, selectedDate) => {
        
        if(selectedDate == undefined){
            setShowHasta(false);
            return;
        }

        const currentDate = selectedDate || date;        
        setShowHasta(Platform.OS === 'ios');
        setDateHasta(currentDate);
        navigation.fechaHasta = currentDate;
        
        rendicionesPorFecha(dateDesde, selectedDate);
    };
    
    const showModeHasta = (currentMode) => {
        setShowHasta(true);
        setModeHasta(currentMode);
    };
    
    const showDatepicker = () => {
        showModeHasta('dateHasta');
    };
    
    const handleFechaDesde = async () => {
        setShowDesde(true);
    }

    const handleFechaHasta = async () => {
        setShowHasta(true);
    }

    const refreshList = () => {                
            setRefreshing(true);
            fetchData(dateDesde,dateHasta);
    
            wait(500).then(() => setRefreshing(false));
        
    }
    
    return (
        <>
            <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
                <View style={styles.container}>
                    <Appbar.Header style={styles.header} >
                        <TouchableOpacity onPress={navigation.openDrawer} style={{marginLeft: 10}}>
                            <SvgFormatter SvgPath={SandwichSVG} style={styles.sandwich} />
                        </TouchableOpacity>
                        <View style={
                        {                                                        
                            position: 'relative',                                                        
                        }}>
                            { Platform.OS == 'web' ?
                                <HeaderCard style={{marginTop: 80}} whiteColor={true}/>
                                :
                                <HeaderCard style={{marginBottom: 15}} whiteColor={true}/>
                            }
                        </View>
                        <TouchableOpacity onPress={handleBuscarGastos} style={{marginRight: 10}}>
                            <SvgFormatter SvgPath={LookupSVG} />
                        </TouchableOpacity>
                    </Appbar.Header>                    
                    
                    <View  id="con">                        
                        <Text style={styles.panelGastos}>Otras Rendiciones</Text>                        
                    </View>
                    {                            
                                <View style={{marginTop: 10, marginLeft: 30}}>
                                    <View style={{flexDirection:'row', marginBottom:10}}>
                                        <Text style={styles.labelStyle}>Desde: </Text>                                                                        
                                        { Platform.OS == 'web' ?
                                            <View style={{width: '10%'}}>
                                                {DateTimePickerWeb(dateDesde,"fechaDesde")}
                                            </View>
                                            :
                                            <View>
                                                <TouchableOpacity onPress={handleFechaDesde}>
                                                        <Text style={styles.labelFecha}>{ dateDesde.getDate() + "/" + (dateDesde.getMonth() + 1) + "/" + dateDesde.getFullYear()}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        }
                                        {showDesde && (
                                            <View>                                            
                                                <DateTimePicker
                                                testID="dateTimePicker"
                                                value={dateDesde}
                                                maximumDate={new Date()}
                                                mode={modeDesde}
                                                is24Hour={true}
                                                display="default"
                                                onChange={onChangeDesdeMobile}                                        
                                                />
                                            </View>
                                        )}
                                    </View>
                                    <View style={{flexDirection:'row'}}>
                                        <Text style={styles.labelStyle}>Hasta: </Text>                                    
                                        
                                        { Platform.OS == 'web' ?
                                            <View style={{width: '10%'}}>
                                                {DateTimePickerWeb(dateHasta,"fechaHasta")}
                                            </View>
                                            :
                                            <View>
                                                <TouchableOpacity onPress={handleFechaHasta}>
                                                    <Text style={styles.labelFecha}>{ dateHasta.getDate() + "/" + (dateHasta.getMonth() + 1) + "/" + dateHasta.getFullYear()}</Text>
                                                </TouchableOpacity>
                                            </View>
                                        }
                                        {showHasta && (
                                            <DateTimePicker
                                            testID="dateTimePicker"
                                            value={dateHasta}
                                            maximumDate={new Date()}
                                            mode={modeHasta}
                                            is24Hour={true}
                                            display="default"
                                            onChange={onChangeHastaMobile}                                        
                                            />
                                        )}
                                    </View>                                    
                                </View>                                    
                    }
                    <View style={styles.panel}>
                        {loadingSpiner ? <ActivityIndicator size="large" color="#00ff00" /> :
                            <View>
                                <View style={styles.greenPlus}>
                                    <TouchableOpacity onPress={() => navigation.navigate('Estado', null)}>
                                    {Platform.OS == "web" ?
                                        <img src={GreenPlusSVG} style={{ width: '80px'}} />
                                        : <SvgFormatter style={styles.greenPlus} SvgPath={GreenPlusSVG} />
                                    }
                                    </TouchableOpacity>
                                </View>  
                                {
                                    sinGastos
                                        ?
                                    <View style={{alignSelf: 'center'}}>
                                        <Text style={styles.textSinGastos}>No tenés gastos.</Text>
                                    </View>
                                        :  
                                    <TabEstados refreshList={refreshList} handleMisGastosPageNavigate={handleVolverAlt} 
                                    handleDetalleRendicionesNav={handleDetalleRendiciones} 
                                    rendiciones={rendiciones} />
                                }
                            </View>
                        }
                    </View>
                </View>
            </ScrollView>
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
        </>
    );
}

export default OtrasRendiciones;