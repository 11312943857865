import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Text,Appbar } from 'react-native-paper';
import styles from './styles';
import { TouchableOpacity, View, Image, ScrollView, RefreshControl, ActivityIndicator, StyleSheet, Platform } from 'react-native';
import TabEstados from '../../components/TabEstados'
import HeaderCard from '../../components/header-tarjeta'
import SandwichSVG from '../../resources/Listar Gastos/ExpandSandwich.svg'
import LookupSVG from '../../resources/Listar Gastos/WhiteLookUp.svg'
import DropDownPicker from 'react-native-dropdown-picker'
import Toast from 'react-native-toast-message'
import DropdownMisGastosSVG from '../../resources/MisGastos/DropdownMisGastosSVG.svg'
import GreenBackgroundApprovedSVG from '../../resources/MisGastos/ApprovedGreenBackground.svg'
import useFetch from '../../hooks/useFetch'
import { API_URL } from '../../config/constants';
import SvgFormatter from '../../../svgFormatter';
import { CardContext } from '../../context/CardContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';

const MisGastos = ({ navigation }) => {
    const [visible, setVisible] = useState(false);
    const [selecItem, setSelecItem] = useState(-1);
    const [rendiciones, setRendiciones] = useState([]);
    const [loadingSpiner, setLoadingSpiner] = useState(true);
    const [sinGastos, setSinGasto] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [sinPeriodo, setSinPeriodo] = useState(false);
    const {card} = useContext(CardContext);

    const handleDetalleRendiciones = useCallback(() => {
        navigation.navigate('DetalleRendiciones');
    })
    const handleVolverAlt = () => {
        navigation.navigate('Estado');
    }
    const handleBuscarGastos = useCallback(() => {
        navigation.navigate('BuscarGastos', rendiciones);
    })

    function changeVisibility(state) {
        setVisible(state);
    }

    const toastConfig = {
        'exito': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toast}>
                    <SvgFormatter SvgPath={GreenBackgroundApprovedSVG} style={{ marginRight: 10 }} />
                    <Text style={styles.toastText}>Tu rendición fue realizada {"\n"}con éxito.</Text>
                </View>
            </View>
        ),
        'error': (internalState) => (
            <View style={{ padding: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <View style={styles.toastError}>
                    <Image source={require('../../resources/MisGastos/GreenBackgroundFailedPNG.png')} style={{ marginRight: 10, width: 30, height: 30 }} />
                    <Text style={styles.toastText}>Error al realizar rendición.</Text>
                </View>
            </View>
        ),
        'info': () => { },
        'any_custom_type': () => { }
    }
    const showToast = (isOk) => {
        try{
            switch (isOk) {
                case true:
                    Toast.show({ type: 'exito' });
                    return;
                case false:
                    Toast.show({ type: 'error' });
                    return;
            }
        }
        catch(e) { console.error(e); }        
    }  

    const handlePeriodos = async (periodo) => {
        const selecPeriodos = await AsyncStorage.getItem('@selecPeriodos')
        if( selecPeriodos != null ){
            fetchData(selecPeriodos);
        } else{
            fetchData(periodo);
        } 
       
    }

    const enviarRendiciones = async () => {
        var holdStorage = [];
        var rendicionesExistentes;
        try {
            rendicionesExistentes = await AsyncStorage.getItem('/rendiciones');
        } catch (error) {
            console.log(error);
        }
        if (rendicionesExistentes) {
            holdStorage = JSON.parse(rendicionesExistentes)
            var userToken = await AsyncStorage.getItem('@storage_Key');
            for (let i = 0; i < holdStorage.length; i++) {
                var url = API_URL + "Gastos/rendicion/" + holdStorage[i].rendicionId;
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${userToken}`
                    },
                    body: JSON.stringify(holdStorage[i])
                });
                const status = response.status;
            }
            await AsyncStorage.removeItem('rendiciones')
        }
    }

    const { loading, datos } = ordenarPeriodos();

    function ordenarPeriodos() {
        var periodos = useFetch(API_URL + '/Periodos/' + card.idTarjeta);
        
        if (periodos.datos != undefined && periodos.datos != null) {
            var periodosIniciales = []

            periodos.datos.map((x, i) => {
                periodosIniciales.push({ 'fecha': moment(x.titulo.split("-")[0].replace(" ", ""), "DD/MM/YYYY"), index: i  });
            });

            var orden = periodosIniciales.sort((a, b) => a.fecha.unix() - b.fecha.unix()).reverse();
            var periodosOrdenados = [];

            orden.map((x, i) => {
                periodosOrdenados.push(periodos.datos[x.index]);
            });
            periodos.datos = periodosOrdenados;
            return periodos
        }
        return periodos;

    }

    async function callToast() {
        try{ 
            await AsyncStorage.getItem('STATUS', async (err, result) => {
                if (result) {
                    await AsyncStorage.removeItem('STATUS').then(() => {
                        setTimeout(() => {
                            if (result != "200")
                                showToast(false);
                            else
                                showToast(true);
                        }, 2000);
                    });
                }
            });

        } catch(e) { console.error(e); }
        
    }
    callToast();

    useEffect(() => {
        if (datos && selecItem === -1) {
            setSelecItem(datos[0] != null ? datos[0].id : [])
            fetchData(datos[0] != null ? datos[0].id : [])
            enviarRendiciones();
            navigation.addListener('focus', () => {
                handlePeriodos(datos[0] != null ? datos[0].id : [])
            });
        }
    }, [datos])

    const fetchData = async (periodo) => {
       
        if (datos.length > 0) {
            var url = API_URL + '/Gastos/periodo';
            var userToken = await AsyncStorage.getItem('@storage_Key');
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${userToken}`
                },
                body: JSON.stringify({
                    id: periodo,
                    idTarjeta: card.idTarjeta

                })
            })
            const data = await response.json();
            setRendiciones(data);
            setLoadingSpiner(false)
            if (data.length == 0) {
                setSinGasto(true)

            } else {
                setSinGasto(false)
            }

        } else {
            setSinPeriodo(true);
            setLoadingSpiner(false)
        }
    }

    const onItemChanged = async (item, index) => {
        setSelecItem(item.value);
        fetchData(item.value);
        await AsyncStorage.setItem('@selecPeriodos', JSON.stringify(item.value));
    }

    const wait = timeout => {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    };
    const onRefresh = React.useCallback(() => {
        setRefreshing(true);
        if (selecItem === -1) {
            fetchData(selecItem || []);
        }
        else {
            if (datos[0] != null) {
                fetchData(datos[0].id || []);
            }
            else {
                console.log("No tiene tarjeta corporativa asociada");
            }
        }

        wait(500).then(() => setRefreshing(false));
    }, []);

    return (
        <>
            <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
                <View style={styles.container}>
                    <Appbar.Header style={styles.header} >
                        <TouchableOpacity onPress={navigation.openDrawer} style={{marginLeft: 10}}>
                            <SvgFormatter SvgPath={SandwichSVG} style={styles.sandwich} />
                        </TouchableOpacity>
                        <View style={
                        {                                                        
                            position: 'relative',                                                        
                        }}>
                            { Platform.OS == 'web' ?
                                <HeaderCard style={{marginTop: 80}} whiteColor={true}/>
                                :
                                <HeaderCard style={{marginBottom: 15, marginTop: 15}} whiteColor={true}/>
                            }
                        </View>
                        <TouchableOpacity onPress={handleBuscarGastos} style={{marginRight: 10}}>
                            <SvgFormatter SvgPath={LookupSVG} />
                        </TouchableOpacity>
                    </Appbar.Header>                    
                    
                    <View  id="con"> 
                        { Platform.OS == 'web' ?
                            <Text style={styles.panelGastosWeb}>Mis Gastos</Text>                        
                            :
                            <Text style={styles.panelGastos}>Mis Gastos</Text>                        
                        }                       
                    </View>
                    {
                            loading ? <></> :
                                datos.length > 0 ?
                                    <DropDownPicker
                                        onOpen={() => changeVisibility(true)}
                                        onClose={() => changeVisibility(false)}
                                        items={datos ? datos.map(item => ({ label: item.titulo, value: item.id })) : []}
                                        onChangeItem={onItemChanged}
                                        defaultValue={datos[0] != null ? datos[0].id : ''}
                                        arrowColor={'#80C5A3'}
                                        arrowSize={20}
                                        containerStyle={{ height: 40 }}
                                        style={StyleSheet.flatten(styles.DropDownPickerStyle)}
                                        itemStyle={StyleSheet.flatten(styles.itemStyle)}
                                        placeholder='cargando...'
                                        labelStyle={StyleSheet.flatten(styles.labelStyle)}
                                        icon={() => <SvgFormatter SvgPath={LookupSVG} />}
                                        dropDownStyle={StyleSheet.flatten(styles.dropDownStyle)}
                                        customArrowDown={() => <SvgFormatter SvgPath={DropdownMisGastosSVG} />}
                                        customArrowUp={() => <SvgFormatter SvgPath={DropdownMisGastosSVG} />}
                                    />
                                    : <></>
                        }
                    <View style={styles.panel}>
                        {loadingSpiner ? <ActivityIndicator size="large" color="#00ff00" /> :
                            <View>
                                {
                                    sinGastos
                                        ?
                                    <View style={{alignSelf: 'center'}}>
                                        <Text style={styles.textSinGastos}>No tenés gastos.</Text>
                                    </View>
                                        :  
                                    <TabEstados handleMisGastosPageNavigate={handleVolverAlt} 
                                    handleDetalleRendicionesNav={handleDetalleRendiciones} 
                                    rendiciones={rendiciones} />
                                }
                            </View>
                        }
                    </View>
                </View>
            </ScrollView>
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
        </>
    );
}

export default MisGastos;