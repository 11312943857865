import React, { useContext, useCallback, Fragment, useState } from 'react'
import { Text } from 'react-native-paper'
import { TouchableOpacity, View, ScrollView, SafeAreaView, Dimensions, TextInput, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Comprobante/arrow.svg'
import ResumenRendicion from '../../components/ResumenRendicion'
import { useNavigation } from '@react-navigation/native'
import { API_URL } from '../../config/constants';
//import { AsyncStorage } from 'react-native';
import SvgFormatter from '../../../svgFormatter';
import { useNetInfo } from "@react-native-community/netinfo"
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import KeyboardDone from '../../../keyboardDone';
import HeaderCard from '../../components/header-tarjeta';
import { LegajoContext } from '../../context/LegajoContext';
import LoadScreen from '../../components/LoadScreeen';

import AsyncStorage from '@react-native-async-storage/async-storage';

const Comprobante = (
    rendicionHeredada) => {
    const { legajo } = useContext(LegajoContext);
    const inputAccessoryViewID = 'done';
    const navigation = useNavigation();
    var rendicion = rendicionHeredada.route.params.datosRendicion;
    const { isConnected } = useNetInfo();
    const [saving, setSaving] = useState(false);

    const handleMisGastosNav = useCallback(async () => {

        if (isConnected) {
            await fetchRendicion(rendicion.id).then((async (res) => {
                if (res.ok != null && res.status != null) {
                    await setStatus(res.status.toString());
                    navigation.navigate('MisGastos');
                }
            }))
        } else {
            guardarDatosStorage(rendicion);
            navigation.navigate('MisGastos');
        }
    })

    const handleOtrasRendicionesNav = useCallback(async () => {

        if (isConnected) {
            setSaving(true);
            await saveRendicion().then((async (res) => {
                setSaving(false);
                if (res.ok != null && res.status != null) {
                    await setStatus(res.status.toString());
                    navigation.navigate('OtrasRendiciones');
                }
            }))
        } else {

        }
    })

    const setStatus = async (status) => {
        try {
            await AsyncStorage.setItem(
                'STATUS',
                status
            );
        } catch (error) {
            console.log(error)
        }
    }

    const guardarDatosStorage = async (rendicion) => {
        var holdStorage = [];
        var rendicionesExistentes;
        console.log(rendicion.DATA.comprobante);
        if (rendicion.DATA.comprobante != "") {
            const DATA =
            {
                id: rendicion.id,
                rendicionId: rendicion.id,
                tipoGastoId: rendicion.DATA.tipoGastoId,
                categoriaId: rendicion.DATA.categoriaId,
                comprobante: rendicion.DATA.comprobante,
                comentario: rendicion.DATA.comentario
            }
        }
        else {
            const DATA =
            {
                id: rendicion.id,
                rendicionId: rendicion.id,
                tipoGastoId: rendicion.DATA.tipoGastoId,
                categoriaId: rendicion.DATA.categoriaId,
                comentario: rendicion.DATA.comentario
            }
        }

        rendicionesExistentes = await AsyncStorage.getItem('rendiciones');
        rendicionesExistentes ? holdStorage = JSON.parse(rendicionesExistentes) : holdStorage = [];

        holdStorage.push(DATA);
        await AsyncStorage.setItem("rendiciones", JSON.stringify(holdStorage));
    }

    const fetchRendicion = async (id) => {
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/rendicion/" + id;
        rendicion.DATA.estado = rendicion.estado;
        rendicion.DATA.comentario = inputTexto;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify(rendicion.DATA)
        });
        const status = response.status;
        return response;
    }

    const saveRendicion = async (id) => {
        debugger;
        const userToken = await AsyncStorage.getItem('@storage_Key');
        var url = API_URL + "/Gastos/agregar/";
        rendicion.DATA.estado = rendicion.estado;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${userToken}`
            },
            body: JSON.stringify({
                TipoGastoId: rendicion.DATA.tipoGastoId,
                ImporteTotal: rendicion.importe,
                FechaGasto: rendicion.fecha,
                Comentario: inputTexto,
                Comprobante: rendicion.DATA.comprobante,
                Comprobantes: rendicion.DATA.comprobantes,
                ComprobanteEsPdf: rendicion.DATA.comprobanteEsPdf,
                DesglosesGasto: rendicion.DATA.desgloseGasto,
                Legajo: legajo,
                IdVehiculo: rendicion.DATA.idVehiculo
            })
        });
        const status = response.status;
        return response;
    }

    const [inputTexto, guardarInputTexto] = useState('');

    const handleVolver = useCallback(() => {
        navigation.pop();
    })
    let styleHeight = Dimensions.get('window').height;

    return (
        <Fragment>
            <SafeAreaView style={{ backgroundColor: '#075330' }} />
            <SafeAreaView style={{ backgroundColor: '#075330' }}>
                <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
                    <View style={[styles.container, { minHeight: styleHeight }]}>
                        <View style={styles.card}>
                            <View style={{ flexDirection: 'row' }}>
                                <TouchableOpacity onPress={handleVolver}>
                                    {Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center' }} /> : <ArrowSVG />}
                                </TouchableOpacity>
                                {Platform.OS !== 'web' ? <HeaderCard style={{ height: 30 }} whiteColor={false} /> : <></>}
                            </View>

                            <View style={{ flexDirection: 'row' }}>
                                <View>
                                    <Text style={styles.title}>Comprobante</Text>
                                    <Text style={styles.categories}>Generar Rendición</Text>
                                </View>
                                {Platform.OS == 'web' ? <View style={{ marginLeft: 'auto' }}>
                                    <HeaderCard />
                                </View> : <></>}
                            </View>

                            {rendicion.esNueva ?
                                <></>
                                :
                                <ResumenRendicion datosRendicion={rendicion} />
                            }

                            <View style={[styles.comprobante, { backgroundColor: '#FFFFFF' }]}>
                                <View style={styles.comprobanteItem} >
                                    <Text style={[styles.comprobanteTextGasto, { color: '#353D4A' }]}>Gasto</Text>
                                    <Text style={[styles.comprobanteTextSubtitulo, { color: '#353D4A' }]}>{rendicion.titulo}</Text>
                                </View>
                                <View style={styles.comprobanteItem} >
                                    <Text style={[styles.comprobanteTextTitulo, { color: '#B1B5BE' }]}>Fecha</Text>
                                    <Text style={[styles.comprobanteTextSubtitulo, { color: '#353D4A' }]}>{rendicion.fecha}</Text>
                                </View>
                                <View style={styles.comprobanteItem} >
                                    <Text style={[styles.comprobanteTextTitulo, { color: '#B1B5BE' }]}>Total</Text>
                                    {(rendicion.gastoEnCantidad) ?
                                        <Text style={[styles.comprobanteTextSubtitulo, { color: '#353D4A' }]}>{rendicion.importe}</Text> :
                                        <Text style={[styles.comprobanteTextSubtitulo, { color: '#353D4A' }]}>{rendicion.moneda == 'USD' ? 'USD$ ' + rendicion.importe : '$ ' + rendicion.importe}</Text>}
                                </View>
                            </View>
                            <View style={styles.inputSection}>
                                <Text style={styles.categories}>Comentarios</Text>
                                <TextInput numberOfLines={14} maxLength={500} multiline={true}
                                    style={styles.textInput}
                                    onChangeText={texto => guardarInputTexto(texto)}
                                    inputAccessoryViewID={inputAccessoryViewID}>
                                </TextInput>
                            </View>
                            <KeyboardDone inputAccessoryViewID={inputAccessoryViewID} />
                            <View>
                                {rendicion.esNueva ?
                                    <View>
                                        {saving ?
                                            <LoadScreen />
                                            :
                                            <TouchableOpacity style={styles.btnSiguiente} onPress={() => handleOtrasRendicionesNav()}>
                                                <Text style={styles.textSiguiente}>Finalizar rendición</Text>
                                            </TouchableOpacity>
                                        }
                                    </View>
                                    :
                                    <TouchableOpacity style={styles.btnSiguiente} onPress={() => handleMisGastosNav()}>
                                        <Text style={styles.textSiguiente}>Finalizar rendición</Text>
                                    </TouchableOpacity>
                                }
                            </View>
                        </View>
                    </View>
                </KeyboardAwareScrollView>
            </SafeAreaView>
        </Fragment>
    );
}

export default Comprobante;
