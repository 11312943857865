import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#075330',
      alignItems: 'center',
      justifyContent: 'center',
    },
    containerTicketSubido: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 5,
    },
    avatar: {
        width: 280,
        height: 310,
        borderRadius: 20,
    },
    card: {
        borderTopRightRadius: 18,
        borderTopLeftRadius: 18,
        flex: 1,
        width: '100%',
        backgroundColor: '#FFFFFF',
        marginTop: 20,
        paddingHorizontal: 30,
        paddingTop: 30
    },
    title: {
        color: '#353D4A',
        fontFamily: 'Gotham-Bold',
        fontSize: 30,
        marginBottom: 25
    },
    hr: {
        borderBottomColor: '#B1B5BE4D',
        borderBottomWidth: 1
    },
    fraction:{
        marginVertical: 20,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start'
    },
    icons: {
        marginRight: 10
    },
    textFraction: {
        flexWrap: 'nowrap',
        marginLeft: 8
    },
    categories:{
        color: '#B1B5BE',
        fontFamily: 'Gotham-Bold',
        fontSize: 15
    },
    simpleText:{
        color: '#353D4A',
        marginTop: 5,
        fontFamily: 'Gotham'
    },
    greenText:{
        color: '#0A834B',
        marginTop: 5,
        fontFamily: 'Gotham'
    },
    textInput: {
        padding: 5,
        borderColor: '#B1B5BE4D',
        borderWidth: 1,
        fontWeight: '500',
        fontFamily: 'Gotham',
        marginTop: 5,
    },
    inputSection: {
        marginTop: 10,
        marginBottom: 20
    },
    btnSiguiente: {
        padding: 12,
        margin: 10,
        borderRadius: 10,
        marginBottom: 10,
    },
    textSiguiente: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    cardDesgloseWeb: {
        width: '100%',
        marginVertical: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 5,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    textCardDesgloseWeb: {
        fontSize: 14,
        fontFamily: 'Gotham'
    },
    cardDesglose: {
        marginVertical: 2,
        flexDirection: 'column',
        borderRadius: 20,
        padding: 5,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    textCardDesglose: {
        fontSize: 14,
        fontFamily: 'Gotham',
        padding: 5,
    },
    allCategories: {
        marginTop: 10,
    },
    cardComprobante: {
        display: 'flex',
        marginVertical: 10,
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 20,
        padding: 20,
        elevation: 2,
        shadowColor: 'rgba(0,0,0, .4)',
        shadowOffset: { height: 1, width: 1 },
        shadowOpacity: 1,
        shadowRadius: 1,
        backgroundColor: '#FFFFFF'
    },
    fileName: {
        padding: 7,
        fontWeight: '500',
        height: 30,
        marginBottom: 10,
        textAlignVertical: 'top'
    },
    description: {
        padding: 7,
        fontWeight: '500',
        height: 50,
        textAlignVertical: 'top'
    },
});

export default styles;
