import React, { useState } from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  TextInput
} from 'react-native';
import TicketSVG from '../../resources/AdjuntanteTicket/TicketSVG.svg';
import styles from './styles';
import { useNavigation } from '@react-navigation/native';
import EllipseChekSVG from '../../resources/Estado/ellipseChek.svg';

import SvgFormatter from '../../../svgFormatter';
import * as DocumentPicker from 'expo-document-picker';

import { DataTable } from 'react-native-paper';

import GreenPlusSVG from '../../resources/GestionDesglose/green-plus.svg'
import GreenCloseSVG from '../../resources/GestionDesglose/green-close.svg'


const CameraComponent = (props) => {
  const navigation = useNavigation();
  const [warning, setWarning] = useState(false);
  const [images, setImages] = useState([]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  const imagenDesdeGaleria = async () => {

      var result = await DocumentPicker.getDocumentAsync(
        {
          type: "application/pdf,image/jpeg,image/png",
          copyToCacheDirectory: true,
          allowsMultipleSelection: true,
          multiple: true
        });

    if (!result.cancelled) {
      if ((result.uri).includes('pdf') || (result.uri).includes('jpeg') || (result.uri).includes('jpg') || (result.uri).includes('png')) {

        var tempImgs = [];
        setWarning(false);
        Object.values(result.output).map(async (x, i) => {
          var fileToBase64 = {};
          var newImage = {};
            fileToBase64 = await toBase64(x)
            newImage = { "Comprobante": removeDataImage(fileToBase64), "TituloComprobante": x.name, "DescripcionComprobante": "" };
         

          tempImgs.push(newImage);
          setImages(images => [...images, newImage]);
        });
      } else {
        setWarning(true);
      }

    }
  };

  const removeDataImage = (str) => {
    str = str.replace(/^data:image\/[a-z]+;base64,/, '');
    return str.replace("data:application/pdf;base64,", '');
  };

  const updateImageData = (index, type) => e => {
    let newArr = [...images];

    if (type == "title")
      newArr[index].TituloComprobante = e;
    if (type == "description")
      newArr[index].DescripcionComprobante = e;
    setImages(newArr);
  }


  const goToNextStep = () => {
    props.datosRendicion.tipoGastoId = props.datosRendicion.DATA.tipoGastoId;
    props.datosRendicion.DATA.comprobantes = images;
    navigation.navigate('Comprobante', props);
  };

  const deleteComprobante = (index) => {
    setImages(images.filter((e, i) => i !== index));
  }

  return (
    <View>
      <View>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Título</DataTable.Title>
            <DataTable.Title>Descripción</DataTable.Title>
            <DataTable.Title></DataTable.Title>
          </DataTable.Header>

          {images.map((data, index) => {
            return (
              <DataTable.Row key={"row-" + index}>
                <DataTable.Cell>
                  <TouchableOpacity >
                    <TextInput
                      label="Agregar título"
                      placeholder="Título..."
                      value={data.TituloComprobante.toString()}
                      onChangeText={updateImageData(index, "title")}
                    />
                  </TouchableOpacity>
                </DataTable.Cell>
                <DataTable.Cell>
                  <TouchableOpacity >
                    <TextInput
                      label="Descripción"
                      placeholder="Agregar descripción..."
                      defaultValue={data.DescripcionComprobante}
                      onChangeText={updateImageData(index, "description")}
                    />
                  </TouchableOpacity>
                </DataTable.Cell>
                <DataTable.Cell style={{ display: "flex !important", justifyContent: "flex-end" }}>
                  <TouchableOpacity onPress={() => deleteComprobante(index)}>
                    <SvgFormatter SvgPath={GreenCloseSVG} />
                  </TouchableOpacity>
                </DataTable.Cell>
              </DataTable.Row>
            )
          })
          }
        </DataTable>
      </View>
      <View style={styles.container}>
        {Object.keys(images).length == 0 ? (
          <View
            style={[styles.avatarContainer, { backgroundColor: '#FFFFFF' }]}
          >
            <SvgFormatter SvgPath={TicketSVG} />
          </View>
        ) : (
          <View></View>
        )}
        {warning && <Text style={styles.warning}>Los formatos de archivo permitidos son pdf, jpeg, jpg y png.</Text>}
      </View>
      {Object.keys(images).length < 1 ? (
        <View>
          <TouchableOpacity
            style={[styles.btnSiguiente, { backgroundColor: '#0A834B' }]}
            onPress={() => imagenDesdeGaleria()}
          >
            <Text style={styles.textSiguiente}>Adjuntar Ticket</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.btnSiguiente,
              { backgroundColor: '#0A834B1A', marginBottom: 40 }
            ]}
            onPress={() => navigation.navigate('Comprobante', props)}
          >
            <Text style={[styles.textSiguiente, { color: '#0A834B' }]}>
              Gasto sin comprobante
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View>
          <View style={styles.agregarComprobante}>
            <TouchableOpacity onPress={() => imagenDesdeGaleria()}>
              <SvgFormatter SvgPath={GreenPlusSVG} />
            </TouchableOpacity>
          </View>
          <View style={styles.containerTicketSubido}>
            <SvgFormatter SvgPath={EllipseChekSVG} />
            <Text style={styles.textTicketSubido}>
              Comprobantes subidos correctamente
            </Text>
          </View>
          <TouchableOpacity
            style={[styles.btnSiguiente, { backgroundColor: '#0A834B' }]}
            onPress={() => {
              goToNextStep();
            }}
          >
            <Text style={styles.textSiguiente}>Siguiente</Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};
export default CameraComponent;
