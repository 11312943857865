import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#008B47',
    },
    //header:{
    //  backgroundColor: '#FAFAFA',
    //  justifyContent: 'space-between',
    //  elevation: 0,
    //  marginTop: 40  
    //},

    panel:{
      backgroundColor: '#FAFAFA',
      flex: 1,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25         
    },
    fechas: {
      fontSize: 20,
      color: '#FAFAFA'
    },
    panelGastos:{
      fontSize: 30,
        color: '#FAFAFA',
        fontFamily: 'Gotham'
    },
    sandwich:{
      marginLeft: 20
    },
    headerHolder:{
      paddingLeft: 20,
      paddingBottom: 10
    },
    holder:{
        marginTop: 20,
        backgroundColor: '#FAFAFA',
        height: '100%',
        borderTopRightRadius:20,
        borderTopLeftRadius: 20
    },
    header:{
        justifyContent: "space-between",
        flexDirection: 'row',
        marginTop: 27,
        marginLeft: 10,
        width: 320,
        zIndex: 1,
        minHeight: 140,
        marginBottom: -100
    }







});

export default styles;
