import React, { useState, useCallback, useEffect } from 'react';
import { StyleSheet, Image, TextInput, View, Platform, Button } from 'react-native';
import { Text } from 'react-native-paper';
import styles from './styles';
import { TouchableOpacity } from 'react-native';
import { AuthContext } from './../app/context';
import { API_URL, IS_WEB, IS_ANDROID } from '../../config/constants';
import InputPasswordToggle from '../../components/password-toggle';
import KeyboardDone from '../../../keyboardDone';
import * as Notifications from 'expo-notifications';
//import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import {CardContext} from '../../context/CardContext';

import AsyncStorage from '@react-native-async-storage/async-storage';


const Login = ({ navigation }) => {
  const inputAccessoryViewID = 'done';
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [warning, setWarning] = useState(false);
  const [deviceToken, setDeviceToken] = useState('');
  const { signOut } = React.useContext(AuthContext);
  const {card, setCard } = React.useContext(CardContext);

  useEffect(() => {
    (async () => {
      const token = await registerForPushNotificationsAsync();
      setDeviceToken(token);
    })();
  }, []);

  const handleLogIn = useCallback(() => {
    fetchLogin(username, password);
  });

  const handleSignout = () => {
    if (Platform.OS == "web"){
        AsyncStorage.removeItem("@storage_Key").then(() => {
            window.location.reload();
        });
    }else{
        AsyncStorage.removeItem("@storage_Key").then(() => {
            signOut();
        });
    }
  }

  async function fetchTarjetas () {
    const userToken = await AsyncStorage.getItem('@storage_Key');

    const response = await fetch(API_URL + '/Tarjetas', {
      headers: {
          "Authorization": `Bearer ${userToken}`
      }
    })
    .then((response) => response.json())
    .then((json) => {
      cardValidation(json);
    });
  }

  const cardValidation = (datos) => {
    if(datos.length == 0 || null){
        if(Platform.OS == "web"){
            alert('No posee tarjeta corporativa asociada');
            handleSignout();
        }else{
          Alert.alert('No posee tarjeta corporativa asociada', '',[{text: 'Volver',
          onPress: () => {
          handleSignout();
          }}])
        }
    }else if(datos.length == 1){
      setCard(datos[0]);
      navigation.navigate('TipoRendicion');
    }
  }

  const fetchLogin = async (user, pass) => {
    var pns = '';
    if (Platform.OS === 'android') pns = 'fcm';
    else if (Platform.OS === 'ios') pns = 'apns';

    var url = API_URL + '/login';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userName: user,
        password: pass,
        handle: '',
        pns: '',
        deviceToken: deviceToken
      })
    });

    const status = response.status;
    if (status == 200) {
      const data = await response.json();
      setWarning(false);
      signIn(data.nombre, data.token);
      fetchTarjetas();
    } else {
      setWarning(true);
    }
  };

  const registerForPushNotificationsAsync = async () => {
    let token;

    if (Platform.OS == 'web') {
      return '';
    }

    if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();

      let finalStatus = existingStatus;

      if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }

      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return '';
      }

      token = (await Notifications.getExpoPushTokenAsync({})).data;
    } else {
      console.log('Must use physical device for Push Notifications');
      return "";
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C'
      });
    }

    return token;
  };

  const { signIn } = React.useContext(AuthContext);
  //const {signIn} = AuthContext;
  return (
    <View style={styles.container}>
      <Image
        source={require('../../resources/laserenisima_logo.png')}
        style={{ width: 320, height: 110 }}
      ></Image>
      <Text style={styles.titulo}>Bienvenido!</Text>
      <TextInput
        style={styles.input}
        value={username}
        onChangeText={setUsername}
        inputAccessoryViewID={inputAccessoryViewID}
      ></TextInput>
      <InputPasswordToggle
        inputStyle={{ color: 'white', fontFamily: 'Gotham-Bold', fontSize: 15 }}
        iconColor={'white'}
        style={styles.input}
        value={password}
        onChangeText={setPassword}
        inputAccessoryViewID={inputAccessoryViewID}
      />
      <KeyboardDone inputAccessoryViewID={inputAccessoryViewID}/>
      <View>
        <TouchableOpacity style={styles.btnSecion} onPress={handleLogIn}>
          <Text style={styles.textoEliminar}>Iniciar sesión</Text>
        </TouchableOpacity>
      </View>
      {warning ? (
        <Text style={styles.titulo2}>Contraseña o Usuario Incorrecto</Text>
      ) : (
        <Text style={styles.titulo2}></Text>
      )}
      
    </View>
  );
};

export default Login;
