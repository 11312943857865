
import React, { useCallback, useState, useEffect,Fragment} from 'react';
import { Text, Divider} from 'react-native-paper';
import ResumenRendicion from '../../components/ResumenRendicion';
import { TouchableOpacity, View, ScrollView, FlatList, ActivityIndicator, Dimensions, Platform } from 'react-native'
import styles from './styles'
import ArrowSVG from '../../resources/Categoria/arrow.svg'
import ListSVG from '../../resources/Categoria/list.svg'
import EllipseSVG from '../../resources/Categoria/ellipse.svg'
import EllipseCheckSVG from '../../resources/Categoria/ellipseChek.svg'
import { useNavigation } from '@react-navigation/native'
import { Searchbar } from 'react-native-paper'
import { SafeAreaView } from 'react-native-safe-area-context';
import { API_URL } from '../../config/constants';
import KeyboardDone from '../../../keyboardDone';
import SvgFormatter from '../../../svgFormatter';
import HeaderCard from '../../components/header-tarjeta'

import AsyncStorage from '@react-native-async-storage/async-storage';


const Categoria = ( status ) => {
    const inputAccessoryViewID = 'done';
    let styleHeight = Dimensions.get('window').height;
    let platformHeight = Platform.OS == 'web' ? 250 : 100;
    const navigation = useNavigation();
    const rendicion = status.route.params;
    const [selectedId, setSelectedId] = useState();
    const [loading, setLoading] = useState(true);
    const [datos, setDatos] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);
    rendicion.estado = rendicion.estadoAnterior;
    const [textwarning, setTextwarning] = useState("");
    
    const handleVolver = useCallback(() => {
        navigation.pop();
    })

    const SelectItem =  (prop) =>{
        setSelectedId(prop.id)
        var desgloseGasto = {
            categoriaId: prop.id,
            categoriaDes: prop.nombre,
            centroCostoId: '',
            centroCostoDes: '',
            importe: 0,
            key: Math.floor(Math.random() * 1000000) + 1
        }
        rendicion.DATA.desgloseGasto.push(desgloseGasto);
    }
     //Llamada a la API
    const fetchData = async () => {        
        const userToken = await AsyncStorage.getItem('@storage_Key');
        const response = await fetch(API_URL + '/Gastos/categorias/' + rendicion.DATA.tipoGastoId, {
            headers: {
                "Authorization": `Bearer ${userToken}`
            }
        });
        const data = await response.json();
        setDatos(data);
        setSearchQuery(data);
        setLoading(false);
    }
    useEffect(() => {
        fetchData()
    },[])
    
    //Searcher
    const searchFilterFunction = text => {
        const newData = datos.filter(item => {
            const itemData = `${item.nombre.toUpperCase()}`;
            const textData = text.toUpperCase();
            return itemData.indexOf(textData) > -1;     
        });
        if (newData.length == 0) {
            setSearchQuery(datos);
        }else{
            setSearchQuery(newData);
        }   
    };

    const renderRendicion = ({ item }) => {
        const isSelected = (selectedId === item.id);
        const textColor = isSelected ? '#0A834B' : '#353D4A';
        const fontWeight = isSelected ? "bold" : "normal";
        const Ellipse = isSelected ? <SvgFormatter SvgPath={EllipseCheckSVG} style={styles.icons} /> : <SvgFormatter SvgPath={EllipseSVG}  style={styles.icons}/>;

        return (
            <TouchableOpacity style={[styles.fraction]} onPress={()=>SelectItem(item)} key={item.id}>                   
                <Text style={[styles.greenText , { color: textColor, fontWeight: fontWeight}]}>{item.nombre}</Text>
                <View style={styles.textFraction}> 
                    {Ellipse}
                </View>                 
            </TouchableOpacity>
         );
    }

    const ValidarSiguiente = () => {
        if (!selectedId) {
            setTextwarning("Seleccione una Categoria.");
            return;
        }
        navigation.navigate('CentroCosto', rendicion);
    }

    return (
        <Fragment>          
            <SafeAreaView style={{backgroundColor: '#075330'}}>
                <ScrollView scrollEnabled={false}>
                <View style={[styles.container,{minHeight: styleHeight}]}>
                    <View style={styles.card}>
                        <View style={{flexDirection:'row'}}>
                        <TouchableOpacity onPress={handleVolver}>
                                { Platform.OS == "web" ? <img src={ArrowSVG} style={{ alignSelf: 'center'}}/> : <ArrowSVG/> }
                        </TouchableOpacity>
                        {Platform.OS !== 'web' ? <HeaderCard style={{height:30}} whiteColor={false}/> : <></>}
                        </View>
                       
                        <View style={{flexDirection:'row'}}>
                        <View>
                            <Text style={styles.title}>Seleccionar Categoría</Text>
                            <Text style={styles.categories}>Generar Rendición</Text>
                        </View>
                        {Platform.OS == 'web' ? <View style={{marginLeft:'auto'}}>
                          <HeaderCard />
                        </View> : <></>}
                        </View>
                        {rendicion.esNueva  == true ?
                            <></>
                            :
                            <ResumenRendicion datosRendicion={rendicion}/>             
                        }
                        
                        <View>
                            <Searchbar
                                placeholder="Buscar"
                                selectionColor='green'
                                style={{
                                    backgroundColor: '#FAFAFA', 
                                    elevation: 0,
                                    fontWeight: 'bold', 
                                    fontSize: 0
                                    }}
                                onChangeText={searchFilterFunction}
                                    icon={() => <SvgFormatter SvgPath={ListSVG} />}
                                autoCorrect={false} 
                                inputAccessoryViewID={inputAccessoryViewID}
                            />
                                <Divider style={styles.horizontalLine} />
                                   
                                    {loading ? <ActivityIndicator size="large" color="#00ff00" /> :
                                        <View style={{ height: platformHeight }}> 
                                            <FlatList
                                                data={searchQuery}
                                                renderItem={renderRendicion}
                                                keyExtractor={rendi => `${rendi.id}`}
                                                extraData={selectedId}
                                                initialNumToRender={6}
                                                maxToRenderPerBatch={6}
                                            />
                                        </View> 
                                    }
                                   
                        </View>
                        <KeyboardDone inputAccessoryViewID={inputAccessoryViewID}/>
                        <View>
                                <Text style={styles.textWarning}>{textwarning}</Text> 
                                <TouchableOpacity style={styles.btnSiguiente} onPress={() => ValidarSiguiente()}>
                            <Text style={styles.textSiguiente}>Siguiente</Text>
                            </TouchableOpacity>
                        </View>
                                
                    </View>       
                </View>
                </ScrollView>
            </SafeAreaView>
        </Fragment>
      );
}
export default Categoria;